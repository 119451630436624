import React from 'react';
import './style.scss';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const EmptyList = () => {
  const { t } = useTranslation(['transactions-history']);

  return (
    <div className="tx-empty-list">
      <div className="tx-empty-list-search-icon-rounded">
        <Search className="tx-empty-list-search-icon" />
      </div>
      <span className="tx-empty-list-title">{t('no-transactions-yet')}</span>
      <span className="tx-empty-list-info">{t('no-transactions-info')}</span>
    </div>
  );
};
