import React from 'react';
import { useTranslation } from 'react-i18next';

export const useFilterData = (): ISpotAccountListHeaderFiltersData[] => {
  const { t } = useTranslation(['spot-account']);
  return [
    {
      type: 'show',
      title: 'common:show',
      filters: [
        { value: 'all', label: t('all-funds') },
        { value: 'investments', label: t('investments') },
        { value: 'bonuses', label: t('bonuses') },
      ],
    },
  ];
};
