import React from 'react';
import './style.scss';
import { CommonButton, CommonTileContainer } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { useConnectionWalletModal } from '../../../../hooks';

export const DisconnectedWallet: React.FC<ITxHistoryDisconnectedWalletProps> = ({
  isChainSupported,
}) => {
  const { t } = useTranslation(['transactions-history']);
  const { openModal } = useConnectionWalletModal();

  return (
    <CommonTileContainer>
      <p className="disconnected-wallet-title">{t('disconnected-wallet')}</p>
      <p className="disconnected-wallet-info">{t('connect-wallet-info')}</p>
      {isChainSupported && (
        <CommonButton
          containerClassName="disconnected-wallet-button"
          size="large"
          onClick={openModal}
          label={t('common:connect')}
        />
      )}
    </CommonTileContainer>
  );
};
