import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { notificationsResource, transferRequestResource } from '../api';
import { transferRequestFailure } from '../redux/transactions/actions';

export const useDeleteTransferRequest = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutateAsync, isLoading: isDeletingTransferRequest } = useMutation<
    void,
    Error,
    IDeleteTransferParams
  >(transferRequestResource.deleteTransferRequest);

  const deleteTransferRequest = useCallback(
    async (id: string, decision: TRequestTransferDecision) => {
      mutateAsync(
        { id, data: { decision } },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(notificationsResource.notificationsCacheKey, {
              refetchInactive: true,
            });
            await queryClient.invalidateQueries(notificationsResource.notificationsCountCacheKey, {
              refetchInactive: true,
            });
          },
          onError: (err) => {
            dispatch(transferRequestFailure(err));
          },
        },
      );
    },
    [mutateAsync, queryClient, dispatch],
  );

  return {
    deleteTransferRequest,
    isDeletingTransferRequest,
  };
};
