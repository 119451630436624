import React from 'react';
import { TFunction } from 'react-i18next';

export const getSegmentedControlTabs = (t: TFunction<string[], undefined>) => {
  return [
    {
      label: t('common:sent'),
      value: 'sent',
    },
    {
      label: t('common:received'),
      value: 'received',
    },
  ];
};
