import React from 'react';
import { PaginationItem, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IProps } from './types';
import { paginationText } from '../../theme';

export const CustomPagination = (props: IProps) => {
  const useStyles = makeStyles(() => ({
    selected: {
      backgroundColor: 'transparent!important',
      color: 'black!important',
      border: '1px solid black',
      borderRadius: 100,
    },
    root: {
      color: paginationText,
      backgroundColor: 'transparent!important',
      '&:hover': {
        borderRadius: '100px!important',
      },
      '& .MuiTouchRipple-root': {
        borderRadius: '100px!important',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Pagination
      onChange={props.onChange}
      shape="circular"
      count={props.count}
      boundaryCount={2}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          shape="circular"
          classes={{ selected: classes.selected, root: classes.root }}
        />
      )}
    />
  );
};
