import { protectedApi } from "../decorators";
import { httpService } from "../../../services";
import { envService } from "../../../services/env.service";
import { getMatchedNativeTokenTransactions } from "./helper";
import { web3Service } from "../../../services/blockchain";
import { isBscNetwork, isEthNetwork, noop } from "../../../helpers";
import { __DEV__ } from "../../../constants";

export const TRANSACTIONS_URL = "/transactions";
export const ENHANCE_TRANSACTIONS_URL = "/transactions/extend";
export const ESTIMATED_GAS_PRICE_URL = "/estimated-gas-price";

export class TransactionsResource {
  fetchTransactionCacheKey = "fetchTransactions";
  estimateGasCacheKey = "estimateGasCacheKey";

  @protectedApi()
  async addTransaction(data: IAddTransactionParams) {
    return httpService.post<void>({
      url: TRANSACTIONS_URL,
      data,
    });
  }

  @protectedApi()
  async getTransactions({
    address,
    type,
    currency,
    page,
    limit,
    fiatCurrency,
    network,
  }: IGetTransactionsParams): Promise<IMatchedTransaction[]> {
    await new Promise((resolve) => setTimeout(resolve, 200));
    return Promise.all([
      (async () => {
        const res = await fetch(
          `${envService.getBlockExplorerBaseUrl(network)}?${new URLSearchParams(
            {
              module: "account",
              action:
                currency === "ETH" || currency === "BNB" ? "txlist" : "tokentx",
              address: address,
              ...(currency === "CUSTOM" ||
              currency === "ETH" ||
              currency === "BNB"
                ? {}
                : {
                    contractAddress: isEthNetwork(network)
                      ? envService.getEthTokens(network)[
                          currency as ERC20DefaultTokens
                        ].address ?? ""
                      : envService.getBscTokens(network)[
                          currency as BEP20DefaultTokens
                        ].address ?? "",
                  }),
              offset: limit,
              page,
              startblock: 0,
              endblock: 99999999,
              sort: "desc",
              apikey: isEthNetwork(network)
                ? envService.etherScanApiKey
                : envService.bscScanApiKey,
            } as any
          )}`,
          {
            method: "get",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await res.json();
        if (data.result.length === 0) {
          return [];
        }

        const modifiedData = data?.result.map((item: any) => {
          return {
            ...item,
            value: (item.value / 1000000000000000000).toString(),
          };
        });

        const enhancedData = await httpService.post<IGetTransactionsResponse>({
          url: ENHANCE_TRANSACTIONS_URL,
          data: { data: modifiedData },
          params: { tokenName: currency },
        });
        return enhancedData;
      })(),
    ]).then((res) => {
      return getMatchedNativeTokenTransactions({
        res: res as any,
        type,
        address,
        fiatCurrency,
        network,
      });
    });
  }

  @protectedApi()
  async estimateGas(
    network: Network
  ): Promise<IEstimateGasResponse | undefined> {
    const networkParam = isBscNetwork(network) ? "bsc" : "eth";
    const defaultGasPrice: IEstimateGasResponse = {
      LastBlock: "",
      SafeGasPrice: web3Service.web3.utils.toWei("10", "gwei"),
      ProposeGasPrice: web3Service.web3.utils.toWei("10", "gwei"),
      FastGasPrice: web3Service.web3.utils.toWei("10", "gwei"),
    };

    try {
      return await httpService.get<IEstimateGasResponse>({
        url: ESTIMATED_GAS_PRICE_URL,
        params: {
          network: networkParam,
        },
      });
    } catch (e) {
      __DEV__ && console.log("-------- e", e);

      return defaultGasPrice;
    }
  }

  /** @param {string} gasprice - gwei
   * @param {EthNetwork} network
   */
  async estimateConfirmationTime(gasprice: string, network: Network) {
    if (isBscNetwork(network)) {
      // TODO maybe improve
      return "15";
    }

    const resp = await httpService.get<
      IBlockExplorerResponse<EstimateConfirmationTimeResponse>
    >({
      baseURL: envService.getBlockExplorerBaseUrl(network),
      url: "",
      headers: {
        Authorization: undefined,
      },
      params: {
        module: "gastracker",
        action: "gasestimate",
        gasprice: web3Service.web3.utils.toWei(gasprice, "ether"),
        apikey: envService.etherScanApiKey,
      },
    });

    return resp?.result;
  }

  getBlockExplorerUrl = ({
    value,
    type,
    network,
  }: IGetBlockExplorerUrlParams) => {
    const path = {
      txHash: "tx",
      walletAddress: "address",
    };
    const networkToUrlMap: Record<Network, string> = {
      mainnet: `https://etherscan.io/${path[type]}/${value}`,
      sepolia: `https://sepolia.etherscan.io/${path[type]}/${value}`,
      // bscMainnet: `https://bscscan.com/${path[type]}/${value}`,
      // bscTestnet: `https://testnet.bscscan.com/${path[type]}/${value}`,
    };

    return networkToUrlMap[network];
  };
}

export const transactionsResource = new TransactionsResource();
