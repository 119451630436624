import { useCallback, useEffect, useState } from 'react';

export const useScreen = () => {
  const [filters, setFilters] = useState<TSpotAccountFiltersState>({
    show: 'all',
  });

  const onSelectFilter = useCallback((type: TSpotAccountFilterType, value: string) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [type]: value,
    }));
  }, []);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        coin: 'all',
      };
    });
  }, []);

  return {
    filters,
    onSelectFilter,
  };
};
