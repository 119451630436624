import React from 'react';
import './style.scss';
import logo from '../../assets/images/paypolitan-logo.svg';
import { useTranslation } from 'react-i18next';
import { useFiatPaymentsInfo } from './use-fiat-payments-info';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '../../constants';
import googlePlay from '../../assets/images/icons/GooglePlay.svg';
import appleStore from '../../assets/images/icons/AppStore.svg';

export const FiatPaymentInfoPage = () => {
  const { t } = useTranslation(['fiat-payment']);

  const info = useFiatPaymentsInfo();

  return (
    <div className="fiat-payment">
      <div className={'fiat-payment-logo-container'}>
        <img className="fiat-payment-image" src={logo} alt="icon" />
      </div>
      <div className="fiat-payment-wrapper">
        <p className="fiat-payment-wrapper-title">{t('beneficiary-info')}</p>

        {Boolean(info?.ibanNumber) && (
          <>
            <p className="fiat-payment-wrapper-label">{t('iban-label')}</p>
            <p className="fiat-payment-wrapper-value">{info.ibanNumber}</p>
          </>
        )}

        {Boolean(info?.accountNumber) && (
          <>
            <p className="fiat-payment-wrapper-label">{t('account-number-label')}</p>
            <p className="fiat-payment-wrapper-value">{info.accountNumber}</p>
          </>
        )}

        <p className="fiat-payment-wrapper-label">{t('bic-code-label')}</p>
        <p className="fiat-payment-wrapper-value">{info?.bicCode ?? ''}</p>

        <p className="fiat-payment-wrapper-label">{t('bank-name-label')}</p>
        <p className="fiat-payment-wrapper-value">{info?.bankName ?? ''}</p>

        <p className="fiat-payment-wrapper-label">{t('recipient-name-label')}</p>
        <p className="fiat-payment-wrapper-value">{info?.recipientName ?? ''}</p>

        <p className="fiat-payment-wrapper-label">{t('amount-label')}</p>
        <p className="fiat-payment-wrapper-value">{info.fiatAmount}</p>

        <p className="fiat-payment-wrapper-label">{t('desktop-label')}</p>

        <div className="fiat-payment-wrapper-download">
          <div>
            <a target="_blank" rel="noreferrer" href={GOOGLE_PLAY_LINK}>
              <img src={googlePlay} alt="download from google play" />
            </a>
          </div>
          <div>
            <a target="_blank" rel="noreferrer" href={APP_STORE_LINK}>
              <img src={appleStore} alt="download from apple store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
