import { useSelector } from 'react-redux';
import { getUserApiWallets } from '../redux/users/selectors';

export const useImportedWallet = (address?: string) => {
  const walletAddress = address?.toLowerCase() ?? '';
  const apiWallets = useSelector(getUserApiWallets);
  const importedWallets = apiWallets.filter((wallet) => wallet.type === 'imported');

  const isImportedWallet = importedWallets
    .map((wallet) => wallet.address.toLowerCase())
    .includes(walletAddress);

  return isImportedWallet;
};
