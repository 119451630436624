import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BaseModal } from "../base-modal";
import { CommonButton } from "../common-button";
import "./style.scss";
import { Config } from "../../constants/env.config";
import { web3Service } from "../../services/blockchain";
import { setCurrentChainIdBlockchainError } from "../../redux/blockchain/actions";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants";

export const SpotAccountModal: React.FC<ISpotAccountModalParams> = (props) => {
  const { t } = useTranslation("common");
  const isProd = Config.ENV === "PRODUCTION";

  const history = useHistory();

  const dispatch = useDispatch();

  const handleChangeNetwork = async () => {
    try {
      await web3Service.requestMetamaskNetworkChange(isProd ? 1 : 11155111);
      props.onCloseModal();
    } catch (e) {
      dispatch(setCurrentChainIdBlockchainError(e as Error));
    }
  };

  return (
    <BaseModal
      visible={props.isOpen}
      onDismiss={() => {
        props.onCloseModal();
        history.push(routes.DASHBOARD);
      }}
    >
      <div className="spot-account-modal-text-container">
        <span className="spot-account-modal-text">
          {isProd
            ? t("spot-account-mainnet-switcher")
            : t("spot-account-testnet-switcher")}
        </span>
      </div>
      <div className="spot-account-modal-actions">
        <CommonButton
          labelClassName="spot-account-schedule-redeem-button-label"
          className="spot-account-modal-no-button"
          onClick={() => {
            props.onCloseModal();
            history.push(routes.DASHBOARD);
          }}
          label={t("no")}
        />
        <CommonButton onClick={handleChangeNetwork} label={t("yes")} />
      </div>
    </BaseModal>
  );
};
