import Web3 from "web3";
import { translate } from "../../api";
import MetaMaskOnboarding from "@metamask/onboarding";
import { AbstractProvider, TransactionConfig } from "web3-core";
import EthereumProvider from "@walletconnect/ethereum-provider";
import CoinbaseWalletSDK, {
  CoinbaseWalletProvider,
} from "@coinbase/wallet-sdk";
// import { Web3ConnectionError } from './errors';
import { BEP20_ABI, ERC20_ABI } from "./abi";
import { asyncStorageService } from "../local-storage.service";
import { MetaMaskInpageProvider } from "@metamask/providers";
import {
  __DEV__,
  addChainToMetamaskParams,
  chainIdToNetworkMap,
  CRYPTO_BALANCE_PRECISION,
  ETH_DECIMALS,
  TOKEN_ICON_URLS,
  METAX_BALANCE_PRECISION,
  WALLET_CONNECT_CLIENT_META,
  // BSC_NETWORKS,
  TOKEN_APPROVAL_AMOUNT,
  ETH_NETWORKS,
  USDT_DECIMALS,
} from "../../constants";
import { Unit } from "web3-utils";
import {
  IInitWalletConnectProviderParams,
  IApproveErc20TokenTransactionParams,
  ISendInternalWalletTransactionParams,
  ISendTransactionParams,
} from "./importable.types";
// import { ITxData } from '@walletconnect/types';
import { isEthNetwork } from "../../helpers";
import { serializeError } from "eth-rpc-errors/dist/utils";
import Decimal from "decimal.js";
import { envService } from "../env.service";
// import { IWalletConnectProviderOptions } from '@walletconnect/types';
import { store } from "../../redux/store";
import { useConnectionWalletModal } from "../../hooks";

import { setConnectionWalletModalVisible } from "../../redux/app/actions";

import {
  resetWalletConnectQrCodeUrl,
  resetWalletConnectQrModalError,
  setWalletConnectQrCodeUrl,
  setWalletConnectQrModalError,
} from "../../redux/blockchain/actions";

export const PROJECT_ID = "1b6c0ad1ab0da1272177cec902ab465d";

interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

export class Web3Service {
  constructor() {
    this._web3 = new Web3(Web3.givenProvider);
    this.ethereumProvider = Web3.givenProvider;
    this.abortControllersMap = {};
  }

  private readonly _web3: Web3;
  private abortControllersMap: Record<string, Maybe<AbortController>>;
  private ethereumProvider: Maybe<
    MetaMaskInpageProvider & CoinbaseWalletProvider
  > = null;
  private metamaskOnboarding = new MetaMaskOnboarding();
  private coinbaseWallet = new CoinbaseWalletSDK({
    appName: "Paypolitan",
    appLogoUrl: "https://paypolitan.io/static/media/logo.c6457255.svg",
    darkMode: false,
  });
  initProvider = async ({
    walletType,
    onAccountsChanged,
    onChainChanged,
    onDisconnected,
    onConnected,
  }: IEthProviderSubscriptionParams) => {
    try {
      await this.disconnect();
      if (walletType === "metamaskExtension") {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
          const provider = (window.ethereum as any)?.providers
            ? ((window.ethereum as any)?.providers?.find(
                (e: any) => Object.assign({}, e)?.isMetaMask === true
              ) as MetaMaskInpageProvider)
            : window.ethereum;
          if (provider) {
            await this.onInitProvider(provider as AbstractProvider, {
              walletType,
              onAccountsChanged,
              onChainChanged,
              onDisconnected,
              onConnected,
            });

            onConnected && onConnected({}, "Metamask", "metamaskExtension");
          }
          this.metamaskOnboarding.stopOnboarding();
        } else {
          this.metamaskOnboarding.startOnboarding();
        }
        await asyncStorageService.setItem(
          "latestWalletType",
          "metamaskExtension"
        );
      } else if (walletType === "walletConnect") {
        const walletConnectProvider = this.getWalletConnectProviderInstance();
        const walletConnectAddresses = await (
          await walletConnectProvider
        ).enable();

        console.log(walletConnectProvider, "after enable provider");
        await this.initWalletConnectProvider({
          walletType,
          onConnected,
          onChainChanged,
          onAccountsChanged,
          onDisconnected,
          provider: await walletConnectProvider,
          addresses: walletConnectAddresses,
        });
        await asyncStorageService.setItem("latestWalletType", "walletConnect");
      } else if (walletType === "paypolitan") {
        this.toggleWalletConnectCustomQrCodeErrorMessage();
        const paypolitanProvider = this.getWalletConnectProviderInstance({
          qrcode: false,
        });
        this.subscribeToWalletConnectDisplayingQr(await paypolitanProvider);
        const paypolitanAddresses = await (await paypolitanProvider).enable();
        this.toggleWalletConnectCustomQrCodeModal();
        await this.initWalletConnectProvider({
          walletType,
          onConnected,
          onChainChanged,
          onAccountsChanged,
          onDisconnected,
          provider: await paypolitanProvider,
          addresses: paypolitanAddresses,
        });
        await asyncStorageService.setItem("latestWalletType", "paypolitan");
      } else if (walletType === "coinbase") {
        const existingProvider = (window.ethereum as any)?.providers?.find(
          (e: any) => Object.assign({}, e)?.isCoinbaseWallet === true
        ) as CoinbaseWalletProvider;
        if (existingProvider && existingProvider.isCoinbaseWallet) {
          await this.onInitProvider(existingProvider, {
            walletType,
            onAccountsChanged,
            onChainChanged,
            onDisconnected,
            onConnected,
          });
        } else {
          const provider = this.coinbaseWallet.makeWeb3Provider(
            addChainToMetamaskParams[ETH_NETWORKS.mainnet.chain_id].rpcUrls[0],
            ETH_NETWORKS.mainnet.chain_id
          );
          await this.onInitProvider(provider, {
            walletType,
            onAccountsChanged,
            onChainChanged,
            onDisconnected,
            onConnected,
          });
          await asyncStorageService.setItem("latestWalletType", "coinbase");
        }
        onConnected && onConnected({}, "Coinbase", "coinbase");
      }
    } catch (e: any) {
      const serializedError = serializeError(e);
      if (serializedError?.message !== "User closed modal") {
        __DEV__ && console.log("-------- ", serializedError);
        throw serializedError;
      }
    }
  };

  private subscribeToWalletConnectDisplayingQr = (
    provider: EthereumProvider
  ) => {
    provider.on("display_uri", (url: string) => {
      const uri = url;
      this.toggleWalletConnectCustomQrCodeModal(uri);
      __DEV__ && console.log("Displaying qr code modal error ==>");
    });
    provider.on("disconnect", (args: ProviderRpcError) => {
      if (args.message) {
        const message = args.message;
        this.toggleWalletConnectCustomQrCodeErrorMessage(message);
        __DEV__ && console.log("Disconnection qr code modal error ==>");
      }
    });
  };

  private initWalletConnectProvider = async ({
    walletType,
    onAccountsChanged,
    onChainChanged,
    onDisconnected,
    onConnected,
    provider,
    addresses,
  }: IInitWalletConnectProviderParams) => {
    onAccountsChanged(addresses);
    onConnected &&
      onConnected({}, /*provider?.connector?.peerMeta?.name,*/ walletType);

    await this.onInitProvider(provider, {
      walletType,
      onAccountsChanged,
      onChainChanged,
      onDisconnected,
      onConnected,
    });
  };

  private getWalletConnectProviderInstance = async (params: any = {}) => {
    return await EthereumProvider.init({
      projectId: PROJECT_ID,
      showQrModal: true,
      chains: [1],
      optionalChains: [1],
      rpcMap: {
        // [BSC_NETWORKS.bscMainnet.chain_id]: BSC_NETWORKS.bscMainnet.rpc,
        // [BSC_NETWORKS.bscTestnet.chain_id]: BSC_NETWORKS.bscTestnet.rpc,
      },
      metadata: WALLET_CONNECT_CLIENT_META,
    });
  };

  private toggleWalletConnectCustomQrCodeModal = (uri?: string) => {
    uri
      ? store.dispatch(setWalletConnectQrCodeUrl(uri))
      : store.dispatch(resetWalletConnectQrCodeUrl());
  };

  private toggleWalletConnectCustomQrCodeErrorMessage = (message?: string) => {
    message
      ? store.dispatch(setWalletConnectQrModalError(message))
      : store.dispatch(resetWalletConnectQrModalError());
  };

  onInitProvider = async (
    provider: any,
    {
      walletType,
      onAccountsChanged,
      onChainChanged,
      onDisconnected,
      onConnected,
    }: IEthProviderSubscriptionParams
  ) => {
    if (provider && provider.request) {
      this.ethereumProvider = provider;
      this._web3.setProvider(provider);
      if (walletType === "metamaskExtension" || walletType === "coinbase") {
        const addresses = await provider.request({
          method: "eth_requestAccounts",
        });
        onAccountsChanged(addresses as string[]);
      }
      const chainId = await provider.request({ method: "eth_chainId" });
      const chainDecimal = this.toBN(chainId as string).toNumber();
      onChainChanged(chainDecimal);

      this.subscribeToEvents({
        onChainChanged,
        onAccountsChanged,
        onDisconnected,
        onConnected,
      });
    }
  };

  tryToGetConnectedData = async ({
    onAccountsChanged,
    onChainChanged,
    onConnected,
    onDisconnected,
  }: Omit<IEthProviderSubscriptionParams, "walletType">) => {
    try {
      const latestWalletType = await asyncStorageService.getItem(
        "latestWalletType"
      );
      const WCProvider = await EthereumProvider.init({
        projectId: PROJECT_ID,
        showQrModal: true,
        chains: [1],
        optionalChains: [1],
        rpcMap: {
          // [BSC_NETWORKS.bscMainnet.chain_id]: BSC_NETWORKS.bscMainnet.rpc,
          // [BSC_NETWORKS.bscTestnet.chain_id]: BSC_NETWORKS.bscTestnet.rpc,
        },
        metadata: WALLET_CONNECT_CLIENT_META,
      });

      WCProvider.modal?.subscribeModal((e) => {
        if (e.open) store.dispatch(setConnectionWalletModalVisible(false));
      });

      if (
        WCProvider.connected &&
        ["walletConnect", "paypolitan"].includes(String(latestWalletType))
      ) {
        await this.initProvider({
          walletType: latestWalletType as IWalletType,
          onAccountsChanged,
          onChainChanged,
          onConnected,
          onDisconnected,
        });
      } else {
        if (latestWalletType) {
          await this.initProvider({
            walletType: latestWalletType as IWalletType,
            onAccountsChanged,
            onChainChanged,
            onConnected,
            onDisconnected,
          });
        } else {
          const addresses = await this.ethereumProvider?.request({
            method: "eth_accounts",
          });
          onAccountsChanged((addresses as string[]) ?? []);
          if (onConnected) {
            if (this._web3.givenProvider?.selectedProvider?.isCoinbaseWallet) {
              onConnected({}, "Coinbase", "coinbase");
            } else {
              onConnected({}, "Metamask", "metamaskExtension");
            }
          }

          if (Array.isArray(addresses) && addresses.length) {
            const chainId = await this.ethereumProvider?.request({
              method: "eth_chainId",
            });
            const chainDecimal = this.toBN(
              (chainId as string) ?? "0"
            ).toNumber();
            onChainChanged(chainDecimal);
          }
        }
      }
    } catch (e) {
      __DEV__ && console.log("-------- e", e);
      onAccountsChanged([]);
    }
  };

  get web3(): Web3 {
    return this._web3;
  }

  subscribeToEvents = ({
    onAccountsChanged,
    onConnected,
    onDisconnected,
    onChainChanged,
  }: Omit<IEthProviderSubscriptionParams, "walletType">) => {
    // Note that this event is emitted on page load.
    // If the array of accounts is non-empty, you're already
    // connected.
    this.ethereumProvider?.on("accountsChanged", (accounts) => {
      onAccountsChanged(accounts as string[]);
    });

    this.ethereumProvider?.on("chainChanged", async (chainId) => {
      window.location.reload();
      // const chainDecimal = this.toBN(chainId as string).toNumber();
      // __DEV__ && console.log('-------- chainDecimal', chainDecimal);
      // onChainChanged(chainDecimal);
    });

    this.ethereumProvider?.on("connect", async (connectInfo) => {
      if (onConnected) {
        onConnected(connectInfo);
      }

      try {
        const accounts = await this.ethereumProvider?.request({
          method: "eth_accounts",
        });
        onAccountsChanged(Array.isArray(accounts) ? accounts : []);
      } catch (e) {
        onAccountsChanged([]);
      }
    });

    if (onDisconnected) {
      this.ethereumProvider?.on("disconnect", (error, payload) => {
        __DEV__ && console.log("-------- error", error);
        // need reload in this case
        onDisconnected();
      });
    }
  };

  unSubscribeFromEvents = ({
    onAccountsChanged,
    onConnected,
    onDisconnected,
    onChainChanged,
  }: Omit<IEthProviderSubscriptionParams, "walletType">) => {
    this.ethereumProvider?.removeListener("accountsChanged", onAccountsChanged);
    this.ethereumProvider?.removeListener("chainChanged", onChainChanged);

    if (onConnected) {
      this.ethereumProvider?.removeListener("connect", onConnected);
    }

    if (onDisconnected) {
      this.ethereumProvider?.removeListener("disconnect", onDisconnected);
    }
  };

  async getBalance(
    wallet: IWallet,
    network: Network,
    customTokens: ICustomToken[] = []
  ) {
    switch (wallet.currency) {
      case "ETH":
      case "BNB":
        const bal = await this._web3.eth.getBalance(wallet.address);

        return this.formatBalanceFromWei(bal);
      case "METAX":
      case "EPAN":
      case "POLVEN":
        if (!wallet?.tokenAddress) {
          return null;
        }

        const balance = await this.getErc20Contract(wallet.tokenAddress)
          .methods.balanceOf(wallet.address)
          .call();
        return this.formatTokenBalance(
          balance,
          String(ETH_DECIMALS),
          METAX_BALANCE_PRECISION
        );
      case "BEPAN":
        if (!wallet?.tokenAddress) {
          return null;
        }
        const bep20Balance = await this.getBep20Contract(wallet.tokenAddress)
          .methods.balanceOf(wallet.address)
          .call();
        return this.formatTokenBalance(
          bep20Balance,
          String(ETH_DECIMALS),
          METAX_BALANCE_PRECISION
        );
      case "USDT":
        if (!wallet?.tokenAddress) {
          return null;
        }

        const usdtBalance = await this.getErc20Contract(wallet.tokenAddress)
          .methods.balanceOf(wallet.address)
          .call();

        console.log("-------- usdtBalance", usdtBalance);
        return this.formatTokenBalance(usdtBalance, String(USDT_DECIMALS));
      // case "METAX":
      default: {
        const token = customTokens.find(
          (item) =>
            item.address.toLowerCase() === wallet.tokenAddress?.toLowerCase()
        );

        if (!token) {
          return null;
        }

        return this.getTokenBalance(wallet.address, token, network);
      }
    }
  }

  async getTokenBalance(
    address: string,
    customToken: ICustomToken,
    network: Network
  ) {
    try {
      const contract = isEthNetwork(network)
        ? this.getErc20Contract(customToken.address)
        : this.getBep20Contract(customToken.address);

      const balance = await contract.methods.balanceOf(address).call();

      return this.formatTokenBalance(balance, customToken.decimals.toString());
    } catch (e) {
      __DEV__ &&
        console.log(
          "-------- getErc20TokenBalance customToken",
          e,
          customToken
        );
      return "0";
    }
  }

  async getEstimatedGas({
    from,
    to,
    value,
    data,
  }: TransactionConfig): Promise<IGetEstimatedGasResponse> {
    const estimatedGas = await this._web3.eth.estimateGas({
      from,
      to,
      value,
      ...(data ? { data } : {}),
    });
    const gasPrice = await this._web3.eth.getGasPrice();

    return { gas: estimatedGas, gasPrice };
  }

  sendTransaction = async ({
    executionAbortControllerName,
    toAddress,
    fromWallet,
    amount,
    customToken,
    // connector,
    onReceipt,
    onError,
    userId,
    chainId,
    estimatedGas,
  }: ISendTransactionParams): Promise<Maybe<string>> => {
    const { from, to, value, gasPrice, data, gas } = customToken
      ? await this.prepareErc20TokenTxData({
          fromWallet,
          toAddress,
          amount,
          customToken,
          chainId,
        })
      : await this.prepareEthTxData({
          fromWallet,
          toAddress,
          amount,
          chainId,
        });

    // if (fromWallet.type === 'metamaskExtension' || fromWallet.type === 'coinbase') {
    return this.sendMetamaskExtensionTransaction({
      executionAbortControllerName,
      userId,
      onReceipt,
      onError,
      from,
      to,
      value,
      gasPrice,
      data,
      gas: estimatedGas,
      chainId,
    });
    /* } else if (connector && connector.connected) {
      return this.sendWalletConnectTransaction({
        connector,
        from: `${from}`,
        to,
        value: `${value}`,
        gasPrice: `${gasPrice}`,
        gasLimit: gas,
        data,
        chainId,
      });
    }*/
  };

  // sendWalletConnectTransaction = async <T = string>({
  //   connector,
  //   ...txData
  // }: ISendWalletConnectTransactionParams) => {
  //   const txDataToSend: ITxData = {
  //     ...txData,
  //     gas: undefined,
  //     gasLimit: undefined,
  //   };
  //
  //   const timeout = new Promise<null>((_, reject) => {
  //     setTimeout(
  //       reject,
  //       WALLET_CONNECT_TRANSACTION_TIMEOUT,
  //       new WalletConnectTimeOutError(connector?.peerMeta?.name ?? 'Wallet'),
  //     );
  //   });
  //
  //   return Promise.race<Maybe<T>>([connector.sendTransaction(txDataToSend), timeout]).catch(
  //     (err) => {
  //       if (err instanceof WalletConnectTimeOutError) {
  //         connector.killSession();
  //       }
  //
  //       throw err;
  //     },
  //   );
  // };

  addAbortControllerToMap = (type: string) => {
    this.abortControllersMap = {
      ...this.abortControllersMap,
      [type]: new AbortController(),
    };
  };

  removeAbortController = (type: string) => {
    this.abortControllersMap = {
      ...this.abortControllersMap,
      [type]: null,
    };
  };

  removeAbortListenerFromAbortCotroller = (type: string) => {
    this.abortControllersMap[type]?.signal?.removeEventListener(
      "abort",
      this.abortControllerAbortListener()
    );
  };

  addAbortListenerToAbortCotroller = (type: string, callback?: Function) => {
    this.abortControllersMap[type]?.signal?.addEventListener(
      "abort",
      this.abortControllerAbortListener(callback)
    );
  };

  abortControllerAbortListener = (callback?: Function) => () => {
    callback && callback();
  };

  executeAbortToAbortController = (type: string) => {
    this.abortControllersMap[type]?.abort();
  };

  clearAbortController = (type: string) => {
    this.removeAbortController(type);
    this.removeAbortListenerFromAbortCotroller(type);
  };

  sendMetamaskExtensionTransaction = async ({
    executionAbortControllerName,
    userId,
    onReceipt,
    onError,
    ...trxDataWithGas
  }: ISendInternalWalletTransactionParams): Promise<Maybe<string>> => {
    this.addAbortControllerToMap(executionAbortControllerName);

    return new Promise((resolve, reject) => {
      this.addAbortListenerToAbortCotroller(
        executionAbortControllerName,
        () => {
          reject(new Error("Waiting for approval tx error"));
        }
      );
      this._web3.eth
        .sendTransaction({
          to: trxDataWithGas.to,
          from: trxDataWithGas.from,
          value: trxDataWithGas.value,
          data: trxDataWithGas.data,
          gas: trxDataWithGas.gas,
        })
        .once("transactionHash", (hash) => {
          __DEV__ && console.log("-------- transactionHash", hash);
          resolve(hash);
        })
        .once("receipt", (txReceipt) => {
          if (onReceipt) {
            __DEV__ && console.log("-------- txReceipt", txReceipt);
            onReceipt(txReceipt);
          }
        })
        .once("sending", (sending) => {
          __DEV__ && console.log("-------- sending", sending);
        })
        .once("sent", (sending) => {
          __DEV__ && console.log("-------- sent", sending);
        })
        .on("confirmation", (confNumber, receipt) => {
          if (receipt && !receipt.status) {
            __DEV__ && console.log("-------- confirmation receipt", receipt);
            const error = new Error(
              translate("transfer-fee-failure", "cross-chain-bridge")
            );
            onError && onError(error);
            reject(error);
          }
        })
        .on("error", (error) => {
          onError && onError(error);
          reject(error);
        });
    });
  };

  private prepareEthTxData = async ({
    fromWallet,
    toAddress,
    amount,
    // gasPriceInEth,
    chainId,
  }: IPrepareTxDataParams): Promise<TransactionConfig> => {
    const etherValue = this._web3.utils.toHex(
      this._web3.utils.toWei(amount?.toString(), "ether")
    );
    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to: toAddress,
      value: etherValue,
      chainId,
    };
    // const estimatedGas = await this.getEstimatedGas(trxData);
    //
    // const gasPrice = gasPriceInEth
    //   ? this._web3.utils.toWei(gasPriceInEth, 'ether')
    //   : estimatedGas.gasPrice;

    return {
      ...trxData,
      // ...estimatedGas,
      // gasPrice,
    };
  };

  private prepareErc20TokenTxData = async ({
    fromWallet,
    amount,
    // gasPriceInEth,
    customToken,
    toAddress,
    // ethAmount,
    chainId,
  }: IPrepareErc20TokenTxDataParams): Promise<TransactionConfig> => {
    const network = chainIdToNetworkMap[chainId];
    const etherValue = this._web3.utils.toHex(0);
    const to = customToken.address;

    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to,
      value: etherValue,
      chainId,
    };

    const tokenAmount =
      customToken.decimals === 18
        ? this._web3.utils.toHex(
            this._web3.utils.toWei(amount?.toString(), "ether")
          )
        : this._web3.utils.toHex(
            this.toBN(10 ** customToken.decimals).muln(amount)
          );

    const contract = isEthNetwork(network)
      ? this.getErc20Contract(customToken.address)
      : this.getBep20Contract(customToken.address);

    const data = contract.methods.transfer(toAddress, tokenAmount).encodeABI();

    // const estimatedGas = await this.getEstimatedGas({
    //   ...trxData,
    //   data,
    // });

    // const gasPrice = gasPriceInEth
    //   ? this._web3.utils.toWei(gasPriceInEth, 'ether')
    //   : estimatedGas.gasPrice;

    return {
      ...trxData,
      data,
      // ...estimatedGas,
      // gasPrice,
    };
  };

  toBN(value: string | number) {
    return this._web3.utils.toBN(value);
  }

  formatBalanceFromWei(
    balance: string,
    unit: Unit = "ether",
    roundPrecision = CRYPTO_BALANCE_PRECISION
  ) {
    const weiBalance = this._web3.utils.fromWei(String(balance), unit);

    return this.roundBalance(weiBalance, roundPrecision).toString();
  }

  roundBalance(
    balance: string | number,
    roundPrecision: number = CRYPTO_BALANCE_PRECISION
  ) {
    const bal = new Decimal(balance);

    return bal
      .toDecimalPlaces(roundPrecision, Decimal.ROUND_HALF_FLOOR)
      .toNumber();
  }

  formatTokenBalance(
    balance: string,
    decimals: string,
    roundPrecision = CRYPTO_BALANCE_PRECISION
  ) {
    if (Number(decimals) === 18) {
      return this.formatBalanceFromWei(balance, "ether", roundPrecision);
    } else {
      const tokenBalance = new Decimal(balance)
        .dividedBy(10 ** Number(decimals))
        .toNumber();

      return this.roundBalance(tokenBalance, roundPrecision).toString();
    }
  }

  isValidAddress(address: string) {
    return this._web3.utils.isAddress(address);
  }

  disconnect = async () => {
    await asyncStorageService.setItem("latestWalletType", "");
    // this.ethereumProvider?.removeAllListeners();

    // @ts-ignore
    if (typeof this.ethereumProvider?.disconnect === "function") {
      // @ts-ignore
      this.ethereumProvider?.disconnect();
    }

    this.ethereumProvider = null;
    this.web3.setProvider(null);
  };

  getErc20Contract = (address: string) => {
    return new this._web3.eth.Contract(ERC20_ABI, address);
  };
  getBep20Contract = (address: string) =>
    new this._web3.eth.Contract(BEP20_ABI, address);

  getTokenData = async (address: string, network: Network) => {
    const token = isEthNetwork(network)
      ? this.getErc20Contract(address)
      : this.getBep20Contract(address);
    const symbol = await token.methods.symbol().call();
    const decimals = await token.methods.decimals().call();

    return {
      symbol,
      decimals,
    };
  };

  isErc20Token = async (address: string) => {
    try {
      const token = this.getErc20Contract(address);
      await token.methods.totalSupply().call();

      return true;
    } catch (e) {
      return false;
    }
  };

  isBep20Token = async (address: string) => {
    try {
      const token = this.getBep20Contract(address);
      await token.methods.totalSupply().call();

      return true;
    } catch (e) {
      return false;
    }
  };

  calculateTransferGas = async (
    params: IPrepareTxDataParams,
    customToken?: ICustomToken
  ) => {
    const txData = customToken
      ? await this.prepareErc20TokenTxData({ ...params, customToken })
      : await this.prepareEthTxData(params);

    return this.getEstimatedGas(txData);
  };

  approveTokenTransaction = async ({
    executionAbortControllerName,
    customToken,
    amount,
    gasPrice,
    fromWallet,
    spenderAddress,
    onReceipt,
    userId,
    gas,
    chainId,
  }: IApproveErc20TokenTransactionParams): Promise<Maybe<string>> => {
    const network = chainIdToNetworkMap[chainId];
    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to: customToken.address,
      value: "0",
      chainId,
    };

    const tokenAmount = amount
      ? customToken.decimals === 18
        ? this._web3.utils.toHex(
            this._web3.utils.toWei(amount?.toString(), "ether")
          )
        : this._web3.utils.toHex(
            this.toBN(10 ** customToken.decimals).muln(amount)
          )
      : TOKEN_APPROVAL_AMOUNT.toFixed().toString();

    const contract = isEthNetwork(network)
      ? this.getErc20Contract(customToken.address)
      : this.getBep20Contract(customToken.address);

    const data = contract.methods
      .approve(spenderAddress, tokenAmount)
      .encodeABI();

    if (
      fromWallet.type === "metamaskExtension" ||
      fromWallet.type === "coinbase"
    ) {
      return this.sendMetamaskExtensionTransaction({
        executionAbortControllerName,
        userId,
        onReceipt,
        gas,
        gasPrice,
        ...trxData,
        data,
      });
    } /*else if (connector && connector.connected) {
      return this.sendWalletConnectTransaction({
        connector,
        ...trxData,
        gasPrice,
        data,
      });
    }*/

    return null;
  };

  getTransactionReceipt = async (txHash: string) => {
    return this._web3.eth.getTransactionReceipt(txHash);
  };

  addTokenToMetamaskOrCoinbase = async (token: IWallet) => {
    return this.ethereumProvider?.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.tokenAddress,
          symbol:
            envService.envConfig.ENV === "PRODUCTION"
              ? token.currency
              : `${token.currency}`, //-${envService.envConfig.ENV[0]}
          decimals: token.decimals,
          image: TOKEN_ICON_URLS[token.currency],
        },
      },
    });
  };

  requestMetamaskNetworkChange = async (chainId: SupportedChainId) => {
    try {
      await this.ethereumProvider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this._web3.utils.toHex(chainId) }],
      });
    } catch (e) {
      const error = serializeError(e);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.code === 4902) {
        await this.addMetamaskNetwork(chainId);
      } else {
        throw error;
      }
    }
  };

  addMetamaskNetwork = async (chainId: SupportedChainId) => {
    await this.ethereumProvider?.request({
      method: "wallet_addEthereumChain",
      params: [addChainToMetamaskParams[chainId]],
    });
  };
}

export const web3Service = new Web3Service();
