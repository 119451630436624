import React, { useEffect } from "react";
import "./assets/styles/style.scss";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute, ProtectedRoute } from "./routes";
import { routes } from "./constants";
import { NotFoundPage } from "./pages";
import { VersionPage } from "./pages/version";
import { AuthRouter } from "./routes/AuthRouter";
import { AppRouter } from "./routes/AppRouter";
import { initApp } from "./redux/app/actions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  // if (isMobile) {
  //   return (
  //     <Switch>
  //       {/*<Route path={routes.FIAT_PAYMENT} component={FiatPaymentInfoPage} />*/}
  //       <Route path="*" component={MobileDevice} />
  //     </Switch>
  //   );
  // }

  return (
    <div>
      {/*<ErrorBoundary>*/}
      <Switch>
        <Route path={routes.VERSION} component={VersionPage} />
        {/*<Route path={routes.FIAT_PAYMENT} component={FiatPaymentInfoPage} />*/}
        <Route
          exact
          strict
          path="/"
          render={() => <Redirect to={routes.SIGN_IN} />}
        />
        <AuthRoute path="/auth" component={AuthRouter} />
        <ProtectedRoute path="/" component={AppRouter} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      {/*</ErrorBoundary>*/}
    </div>
  );
}

export default App;
