import { useCallback, useState } from 'react';
import { getInitAnchorElState } from './helpers';

export const useFilters = (
  filtersData: ISpotAccountListHeaderFiltersData[],
  onSelectFilter: TOnSelectSpotAccountFilter,
) => {
  const [anchorEl, setAnchorEl] = useState(getInitAnchorElState(filtersData));

  const handleOpenFilterDropdown = useCallback(
    (type: TSpotAccountFilterType) => (event: any) => {
      setAnchorEl((prevState) => ({
        ...prevState,
        [type]: event.currentTarget,
      }));
    },
    [],
  );

  const handleCloseFilterDropdown = useCallback(() => {
    setAnchorEl(getInitAnchorElState(filtersData));
  }, []);

  const onFilterClick = useCallback(
    (type: TSpotAccountFilterType, value: string) => () => {
      onSelectFilter(type, value);
      handleCloseFilterDropdown();
    },
    [onSelectFilter, handleCloseFilterDropdown],
  );

  return {
    anchorEl,
    onFilterClick,
    handleCloseFilterDropdown,
    handleOpenFilterDropdown,
  };
};
