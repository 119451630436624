import {
  EAbortControllers,
  IEthBscBridgeTransactionParams,
  IEthBscPairsData,
  IPrepareEthBscBridgeSwapTrxDataParams,
} from "./importable.types";
import { web3Service, Web3Service } from "./web3.service";
import { bepanToEpanAbi, epanToBepanAbi } from "./abi";
import { __DEV__, networkToChainIdMap } from "../../constants";
import { isBscNetwork, isEthNetwork } from "../../helpers";
import { envService } from "../env.service";

class EthBscBridgeService {
  constructor(web3Svc: Web3Service) {
    this._web3service = web3Svc;
    this.bridgeContracts = {
      EPAN_BEPAN: {
        eth: new this._web3service.web3.eth.Contract(
          epanToBepanAbi,
          envService.getEthNetworks("mainnet").epanToBepanBridgeAddress
        ),
        bsc: null,
      },
    };
  }

  private _web3service: Web3Service;
  bridgeContracts: Record<EthBscBridgePairs, IEthBscPairsData>;

  init(web3service: Web3Service, network: Network) {
    this._web3service = web3service;
    this.bridgeContracts = {
      EPAN_BEPAN: {
        eth: isEthNetwork(network)
          ? new this._web3service.web3.eth.Contract(
              epanToBepanAbi,
              this.getBridgeAddress("EPAN_BEPAN", network)
            )
          : null,
        bsc: isBscNetwork(network)
          ? new this._web3service.web3.eth.Contract(
              bepanToEpanAbi,
              this.getBridgeAddress("EPAN_BEPAN", network)
            )
          : null,
      },
    };
  }

  getContractFee = async (
    type: EthBscBridgePairs,
    network: Network,
    from?: string
  ): Promise<IGetBridgeSwapContractFeeResponse> => {
    const contract = this.getContract(type, network);
    const fee: Maybe<string> = await contract?.methods
      .fee()
      .call({ from: from ?? this._web3service.web3.defaultAccount });
    __DEV__ && console.log("-------- bridge swap contract fee", fee);
    return {
      fee: fee,
      formattedFee: fee ? this._web3service.formatBalanceFromWei(fee) : null,
    };
  };

  getBridgeSwapAllowance = async (
    type: EthBscBridgePairs,
    fromWallet: IWallet,
    network: Network
  ) => {
    const from = fromWallet.address;
    const spender = this.getBridgeAddress(type, network);
    const tokenAddress = this.getEthBscTokenForSwap(type, network)?.address;

    if (!tokenAddress) {
      return null;
    }

    const contract = isEthNetwork(network)
      ? this._web3service.getErc20Contract(tokenAddress)
      : this._web3service.getBep20Contract(tokenAddress);

    return await contract.methods.allowance(from, spender).call();
  };

  bridgeSwap = async ({
    fromWallet,
    // connector,
    network,
    value,
    onReceipt,
    onError,
    type,
  }: IEthBscBridgeTransactionParams) => {
    const feeValue = (
      await this.getContractFee("EPAN_BEPAN", network, fromWallet.address)
    )?.fee;

    if (feeValue) {
      const trxData = await this.prepareBridgeSwapTrxData({
        fromWallet,
        value,
        network,
        type,
        feeValue,
      });

      if (
        fromWallet.type === "metamaskExtension" ||
        fromWallet.type === "coinbase"
      ) {
        return this._web3service.sendMetamaskExtensionTransaction({
          executionAbortControllerName: EAbortControllers.CROSS_CHAIN_BRIDGE,
          onReceipt,
          onError,
          ...trxData,
        });
      } /*else if (connector && connector.connected) {
        return this._web3service.sendWalletConnectTransaction({
          connector,
          ...trxData,
        });
      }*/
    }
  };

  approveBridgeSwapTransaction = async ({
    fromWallet,
    network,
    // connector,
    onReceipt,
    type,
  }: Omit<IEthBscBridgeTransactionParams, "gasPrice" | "value">) => {
    const token = this.getEthBscTokenForSwap(type, network);

    if (!token) {
      return null;
    }

    return this._web3service.approveTokenTransaction({
      executionAbortControllerName: EAbortControllers.CROSS_CHAIN_BRIDGE,
      customToken: token,
      fromWallet,
      // connector,
      spenderAddress: this.getBridgeAddress(type, network),
      onReceipt,
      chainId: networkToChainIdMap[network],
    });
  };

  private prepareBridgeSwapTrxData = async ({
    fromWallet,
    network,
    value,
    feeValue,
    type,
  }: IPrepareEthBscBridgeSwapTrxDataParams) => {
    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to: this.getBridgeAddress(type, network),
      value: this._web3service.web3.utils.toHex(feeValue),
      chainId: networkToChainIdMap[network],
    };

    const tokenAmount = this._web3service.web3.utils.toHex(
      this._web3service.web3.utils.toWei(value?.toString(), "ether")
    );

    const data = isEthNetwork(network)
      ? this.getContract(type, network)
          ?.methods.moveToBnb(tokenAmount)
          .encodeABI()
      : this.getContract(type, network)
          ?.methods.moveToEth(tokenAmount)
          .encodeABI();

    return {
      data,
      ...trxData,
    };
  };

  private getContract = (type: EthBscBridgePairs, network: Network) => {
    return this.bridgeContracts[type][isEthNetwork(network) ? "eth" : "bsc"];
  };

  private getEthBscTokenForSwap = (
    type: EthBscBridgePairs,
    network: Network
  ) => {
    if (isEthNetwork(network) && type === "EPAN_BEPAN") {
      return envService.getEthTokens(network).EPAN;
    } else if (isBscNetwork(network) && type === "EPAN_BEPAN") {
      return envService.getBscTokens(network).BEPAN;
    }

    return null;
  };

  private getBridgeAddress = (type: EthBscBridgePairs, network: Network) => {
    if (type === "EPAN_BEPAN" && isEthNetwork(network)) {
      return envService.getEthNetworks(network).epanToBepanBridgeAddress;
    }
    // else if (type === 'EPAN_BEPAN' && isBscNetwork(network)) {
    //   return envService.getBscNetworks(network).bepanToEpanBridgeAddress;
    // }

    return "";
  };
}

export const ethBscBridgeService = new EthBscBridgeService(web3Service);
