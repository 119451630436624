import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFilters } from './use-filters';
import './style.scss';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/blackArrow.svg';
import { isNull } from '../../../../utils/isNull';

export const ListHeader: React.FC<ISpotAccountListHeaderProps> = ({
  filtersDisabled,
  filtersData = [],
  selectedFilters,
  onSelectFilter,
}) => {
  const { t } = useTranslation(['spot-account']);
  const { anchorEl, handleCloseFilterDropdown, handleOpenFilterDropdown, onFilterClick } =
    useFilters(filtersData, onSelectFilter);

  return (
    <div className="spot-account-header">
      <span className="spot-account-header-title">{t('redeem-schedule')}</span>
      {filtersData.map((item, idx) => {
        const currentAnchorEl = anchorEl?.[item.type as string];
        const selectedFilterLabel =
          item.filters.find((filter) => filter.value === selectedFilters[item.type])?.label ?? '';

        return (
          <div className="spot-account-header-filters-wrapper" key={`${item.type}${idx}`}>
            <div className="spot-account-header-filter-item">
              <span className="spot-account-header-filter-item-title">{`${t(item.title)}:`}</span>
              <Button
                disabled={!!filtersDisabled}
                aria-haspopup="true"
                onClick={handleOpenFilterDropdown(item.type)}
                aria-controls="simple-menu"
              >
                <div className="spot-account-header-filter-button-inner">
                  <span>{t(selectedFilterLabel)}</span>
                  <Arrow className={`${!isNull(currentAnchorEl) ? 'rotate180' : 'rotate0'}`} />
                </div>
              </Button>
            </div>
            <Menu
              keepMounted
              id="simple-menu"
              anchorEl={currentAnchorEl}
              onClose={handleCloseFilterDropdown}
              open={Boolean(currentAnchorEl)}
            >
              {item.filters.map((filter, idx) => (
                <MenuItem
                  key={`${filter.value}${filter.label}${idx}`}
                  onClick={onFilterClick(item.type, filter.value)}
                >
                  <span>{t(filter.label)}</span>
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      })}
    </div>
  );
};
