import React, { useCallback, useMemo } from 'react';
import './style.scss';
import { BaseModal, CommonButton, CommonInput, ContractIconRounded } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { snackbarService } from '../../../../services';
import CopyToClipboard from 'react-copy-to-clipboard';
import ClipboardIcon from '../../../../assets/images/icons/clipboard-icon.svg';
import { useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../../../redux/blockchain/selectors';
import { transactionsResource } from '../../../../api';
import cx from 'classnames';
import { Divider } from '@mui/material';
import { ViewTxButton } from '../view-tx-button';
import moment from 'moment';

export const InfoModal: React.FC<ITxItemModalInfoProps> = ({
  visible,
  onDismiss,
  title,
  date,
  amount,
  currency,
  fee,
  hash,
  description,
  smartContract,
  from,
  transactionType,
  contractAddress,
  sender,
  recepient,
  to,
}) => {
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation(['common']);
  const participants = useMemo(() => {
    return {
      mainParty:
        transactionType === 'sent' ? { ...recepient, wallet: to } : { ...sender, wallet: from },
      secondaryPary:
        transactionType === 'sent' ? { ...sender, wallet: from } : { ...recepient, wallet: to },
    };
  }, [sender, recepient, transactionType]);
  const onClipboardCopied = useCallback(() => {
    snackbarService.showSuccessSnackbar(t('copied'));
  }, [t]);

  const onScanClick = useCallback(() => {
    if (hash) {
      window.open(
        transactionsResource.getBlockExplorerUrl({ value: hash, network, type: 'txHash' }),
      );
    }
  }, []);

  const renderInfoRow = useCallback(
    ({
      label,
      value,
      copy,
      displayWithValue,
    }: {
      label: string;
      value?: string;
      copy?: boolean;
      displayWithValue?: boolean;
    }) => {
      return displayWithValue && !value ? null : (
        <section className="tx-history-info-modal-info-row">
          <span className="tx-history-info-modal-main-label">{t(label)}</span>
          <span />
          {copy && (
            <CopyToClipboard text={value ?? ''} onCopy={onClipboardCopied}>
              <img
                className="tx-history-info-modal-clipboard-icon"
                src={ClipboardIcon}
                alt="clipboard"
              />
            </CopyToClipboard>
          )}
          <span className="tx-history-info-modal-main-value">{value}</span>
        </section>
      );
    },
    [t],
  );

  return (
    <BaseModal
      icon={<ContractIconRounded contract={smartContract?.type} />}
      title={title}
      visible={visible}
      onDismiss={onDismiss}
    >
      {renderInfoRow({
        label: 'date',
        value:
          moment(parseInt(date) * 1000).calendar(null, {
            sameDay: '[Today] HH:mm',
            nextDay: '[Tomorrow] HH:mm',
            nextWeek: 'DD.MM.YYYY HH:mm',
            lastDay: '[Yesterday] HH:mm',
            lastWeek: 'DD.MM.YYYY HH:mm',
            sameElse: 'DD.MM.YYYY HH:mm',
          }) ?? '',
      })}
      {renderInfoRow({ label: 'txhash', value: hash ?? '', copy: true })}
      {smartContract?.type === 'TRANSFER' && (
        <React.Fragment>
          <div className="tx-history-info-modal-section tx-history-info-modal-main-section">
            <span className="tx-history-info-modal-section-title">
              {transactionType === 'received' ? t('sender') : t('receiver')}
            </span>
            {renderInfoRow({
              label: 'name',
              value: participants.mainParty?.firstName ?? '',
              copy: true,
              displayWithValue: true,
            })}
            {renderInfoRow({
              label: 'surname',
              value: participants.mainParty?.lastName ?? '',
              copy: true,
              displayWithValue: true,
            })}
            {renderInfoRow({
              label: transactionType === 'sent' ? 'to-wallet' : 'from-wallet',
              value: participants.mainParty?.wallet ?? '',
              copy: true,
              displayWithValue: true,
            })}
          </div>
          <div className="tx-history-info-modal-section tx-history-info-modal-secondary-section">
            <span className="tx-history-info-modal-section-title">
              {transactionType === 'received'
                ? `${t('receiver')} (${t('you')})`
                : `${t('sender')} (${t('you')})`}
            </span>
            {renderInfoRow({
              label: transactionType === 'sent' ? 'from-wallet' : 'to-wallet',
              value: participants.secondaryPary?.wallet ?? '',
              copy: true,
              displayWithValue: true,
            })}
            {renderInfoRow({
              label: amount ? 'amount' : 'fee',
              value: `${amount ?? fee ?? ''}${currency ?? ''}`,
            })}
          </div>
        </React.Fragment>
      )}
      {renderInfoRow({
        label: contractAddress
          ? 'from-wallet'
          : smartContract?.type === 'SPOT_ACCOUNT'
          ? 'to-wallet'
          : 'wallet',
        value: from,
        copy: true,
      })}
      {renderInfoRow({
        label: 'to-contract',
        value: contractAddress,
        displayWithValue: true,
        copy: true,
      })}
      {smartContract?.type !== 'TRANSFER' &&
        renderInfoRow({
          label: amount ? 'amount' : 'fee',
          value: `${amount ?? fee ?? ''}${currency ?? ''}`,
        })}
      {!!description && (
        <div className="tx-history-info-modal-description-wrapper">
          <Divider className={cx('tx-history-info-modal-divider')} />
          <span className="tx-history-info-modal-main-label">{t('description')}</span>
          <span className="tx-history-info-modal-main-value tx-history-info-modal-description-value">
            {description}
          </span>
        </div>
      )}
      <ViewTxButton
        onScanClick={onScanClick}
        containerClass="tx-history-info-modal-scan-button"
        network={network}
      />
    </BaseModal>
  );
};
