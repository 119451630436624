import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CommonButton,
  FormTextField,
  PageContentContainer,
} from "../../components";
import {
  useConnectionWalletModal,
  useCurrentUserWalletsBalances,
  useExchangeRates,
  useFetchSpotAccount,
  useWalletConnector,
} from "../../hooks";
import exchange from "../../assets/images/icons/exchange.svg";
import "./style.scss";
import { useForm } from "./use-form";
import { ReactComponent as Arrow } from "../../assets/images/icons/blackArrow.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/icons/arrow.svg";
import { Form, Formik } from "formik";
import { getValidationSchema } from "./validation-schema";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { CRYPTO_BALANCE_PRECISION, routes } from "../../constants";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useFilters } from "./use-filters";
import { isNull } from "../../utils/isNull";
import { ConfirmModal, SettingsModal } from "./components";
import { useScreen } from "./use-screen";
import { useSelector } from "react-redux";
import { getSpotTopUpProcessToast } from "../../redux/toast/selectors";

export const TopUpSpotAccountPage = () => {
  const { t } = useTranslation(["spot-account", "common"]);
  const history = useHistory();
  const { visible: toastVisible } = useSelector(getSpotTopUpProcessToast);
  const { spotAccountDetails } = useFetchSpotAccount();
  const { currenciesQuotes } = useExchangeRates(
    spotAccountDetails.currency as DefaultWalletCurrencies
  );
  const { filtersData } = useScreen();

  const {
    formRef,
    initialValues,
    onSubmitForm,
    confirmModalValues,
    confirmModalVisible,
    closeConfirmModal,
    handleTokenAmount,
    settingsModalVisible,
    closeSettingsModal,
    setCalculateRateQueryParams,
    onPressApplyDiscount,
    discountApplied,
    setDiscountApplied,
    promoText,
    discount,
    lockText,
  } = useForm();

  const {
    anchorEl,
    handleCloseFilterDropdown,
    handleOpenFilterDropdown,
    onFilterClick,
  } = useFilters(filtersData as any, (type, value) => {
    switch (type) {
      case "currency":
        formRef.current?.setFieldValue("payWithCurrency", value);
        setCalculateRateQueryParams((prev) => ({ ...prev, payWith: value }));
        break;
      default:
        return;
    }
  });

  const { walletsBalances } = useCurrentUserWalletsBalances({
    currency: formRef.current?.values
      ?.payWithCurrency as DefaultWalletCurrencies,
  });

  const { walletConnected } = useWalletConnector();
  const { openModal: openWalletConnectModal } = useConnectionWalletModal();

  const maxAmount = useMemo(
    () => Number(walletsBalances[0]?.balance || 0),
    [walletsBalances]
  );

  return (
    <div>
      <div
        onClick={() => history.push(routes.BUY_METAX)}
        className="top-up-spot-account-back"
      >
        <ArrowLeft />
        <span className="top-up-spot-account-back-text">
          {t("back", { ns: "common" })}
        </span>
      </div>
      <div className="top-up-spot-account">
        <span
          className="top-up-spot-account-title"
          onClick={() => closeConfirmModal()}
        >
          {t("top-up-spot")}
        </span>
        <span className="top-up-spot-account-description">
          {t("buy-metax-with-eth-usdt")}
        </span>
        <PageContentContainer>
          <div>
            <div>
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={getValidationSchema(
                  maxAmount,
                  formRef.current?.values?.payWithCurrency,
                  walletConnected
                )}
                onSubmit={onSubmitForm}
              >
                {({
                  setFieldValue,
                  errors,
                  values,
                  submitForm,
                  isSubmitting,
                }) => (
                  <>
                    <Form>
                      <div className="top-up-spot-account-input-container">
                        <div className="top-up-spot-account-input-wrapper">
                          <span
                            className={cx(
                              "ccb-secondary-text",
                              "top-up-spot-account-input-label"
                            )}
                          >
                            {t("pay-with")}
                          </span>
                          <FormTextField<ITopUpSpotAccountFormData>
                            className={cx("ccb-input")}
                            placeholder={t("enter-amount", { ns: "common" })}
                            name="payWithAmount"
                            onChange={(e) => {
                              setFieldValue("payWithAmount", e.target.value);
                              handleTokenAmount("pay", e.target.value, values);
                            }}
                            rightComponent={
                              <React.Fragment>
                                {filtersData.map((item) => {
                                  const currentAnchorEl =
                                    anchorEl?.[item.type as string];
                                  const selectedFilterLabel =
                                    values.payWithCurrency;
                                  return (
                                    <div key={item.type}>
                                      <div className="spot-account-header-filter-item">
                                        <Button
                                          aria-haspopup="true"
                                          onClick={handleOpenFilterDropdown(
                                            item.type as any
                                          )}
                                          aria-controls="simple-menu"
                                        >
                                          <div className="spot-account-header-filter-button-inner">
                                            <span className="top-up-spot-account-pay-with-currency">
                                              {t(selectedFilterLabel)}
                                            </span>
                                            <Arrow
                                              className={`${
                                                !isNull(currentAnchorEl)
                                                  ? "rotate180"
                                                  : "rotate0"
                                              } top-up-spot-account-pay-with-arrow`}
                                            />
                                          </div>
                                        </Button>
                                      </div>
                                      <Menu
                                        keepMounted
                                        id="simple-menu"
                                        anchorEl={currentAnchorEl}
                                        onClose={handleCloseFilterDropdown}
                                        open={Boolean(currentAnchorEl)}
                                      >
                                        {item.filters.map((filter, idx) => (
                                          <MenuItem
                                            key={`${filter.value}${filter.label}${idx}`}
                                            onClick={onFilterClick(
                                              item.type as any,
                                              filter.value
                                            )}
                                          >
                                            <span>{t(filter.label)}</span>
                                          </MenuItem>
                                        ))}
                                      </Menu>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            }
                          />
                        </div>
                        <CommonButton
                          className="top-up-spot-account-use-max"
                          labelClassName="top-up-spot-account-use-max-label"
                          label={t("use-max", { ns: "common" })}
                          onClick={() => {
                            setFieldValue("payWithAmount", maxAmount);
                            handleTokenAmount(
                              "pay",
                              maxAmount.toString() ?? "0",
                              values
                            );
                          }}
                          type="button"
                        />
                      </div>
                      <div
                        className={cx("top-up-spot-account-quote", {
                          "top-up-spot-account-quote-error":
                            !!errors.payWithAmount,
                        })}
                      >
                        <span className="top-up-spot-account-quote-label">
                          1 {values.payWithCurrency} ={" "}
                          {(
                            (currenciesQuotes?.quotes?.[
                              spotAccountDetails.currency
                            ]?.USD?.price as any) *
                            (currenciesQuotes?.quotes?.[values.payWithCurrency]
                              ?.USD?.price as any)
                          ).toFixed(CRYPTO_BALANCE_PRECISION)}{" "}
                          {spotAccountDetails.currency}
                        </span>
                      </div>
                      <div className="top-up-spot-account-exchange-indicator">
                        <div className="top-up-spot-account-exchange-indicator-line" />
                        <img
                          className="top-up-spot-account-exchange-indicator-icon"
                          src={exchange}
                        />
                        <div className="top-up-spot-account-exchange-indicator-line" />
                      </div>
                      <div
                        className={cx(
                          "top-up-spot-account-input-wrapper",
                          "top-up-spot-account-amount-input-wrapper"
                        )}
                      >
                        <span
                          className={cx(
                            "ccb-secondary-text",
                            "top-up-spot-account-input-label"
                          )}
                        >
                          {t("receive")}
                        </span>
                        <div className="form-text-filed-wrapper">
                          <FormTextField<ITopUpSpotAccountFormData>
                            className={cx("ccb-input")}
                            placeholder={"0.00"}
                            name="receiveAmount"
                            onChange={(e) => {
                              setFieldValue("receiveAmount", e.target.value);
                              handleTokenAmount(
                                "receive",
                                e.target.value,
                                values
                              );
                            }}
                            rightComponent={
                              <span
                                className={cx("ccb-main-text", "ccb-currency")}
                              >
                                {values.receiveCurrency}
                              </span>
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="top-up-spot-account-input-wrapper">
                        <span
                          className={cx('ccb-secondary-text', 'top-up-spot-account-input-label')}
                        >
                          {t('discount-code')}
                        </span>
                        <FormTextField<ITopUpSpotAccountFormData>
                          className={cx('ccb-input')}
                          placeholder={t('enter-code', { ns: 'common' })}
                          name="discount"
                          onChange={(e) => {
                            setDiscountApplied(false);
                            setFieldValue('discount', e.target.value);
                          }}
                          rightComponent={
                            <span
                              className={cx('top-up-spot-account-apply', {
                                'top-up-spot-account-apply-active': !!values.discount,
                              })}
                              onClick={onPressApplyDiscount(values)}
                            >
                              {discountApplied ? t('common:applied') : t('common:apply')}
                            </span>
                          }
                        />
                        {discount && (
                          <span className="top-up-spot-account-discount-text">{promoText}</span>
                        )}
                        {discountApplied && Boolean(lockText) && (
                          <span className="top-up-spot-account-lock-text">{lockText}</span>
                        )}
                      </div> */}
                      <div className="top-up-spot-account-action-buttons">
                        <CommonButton
                          containerClassName="top-up-spot-account-action-button-cancel-container"
                          labelClassName="top-up-spot-account-action-button-label top-up-spot-account-action-button-cancel-label"
                          className="top-up-spot-account-action-button top-up-spot-account-action-button-cancel"
                          label={t("cancel", { ns: "common" })}
                          disabled={isSubmitting || toastVisible}
                          onClick={() => history.push(routes.BUY_METAX)}
                        />
                        <CommonButton
                          labelClassName="top-up-spot-account-action-button-label"
                          className="top-up-spot-account-action-button"
                          disabled={isSubmitting || toastVisible}
                          loading={isSubmitting}
                          label={
                            walletConnected
                              ? t("buy-metax")
                              : t("connect-wallet")
                          }
                          onClick={
                            walletConnected
                              ? () => submitForm()
                              : () => openWalletConnectModal()
                          }
                        />
                      </div>
                    </Form>
                    <SettingsModal
                      isVisible={settingsModalVisible}
                      closeModal={closeSettingsModal}
                      initialValues={{
                        deadline: values.deadline,
                        slippageTolerance: values.slippage,
                      }}
                      onSubmit={(data) => {
                        setFieldValue(
                          "slippageTolerance",
                          data.slippageTolerance
                        );
                        setFieldValue("deadline", data.deadline);
                      }}
                    />
                  </>
                )}
              </Formik>
            </div>
          </div>
        </PageContentContainer>
      </div>
      <ConfirmModal visible={confirmModalVisible} {...confirmModalValues} />
    </div>
  );
};
