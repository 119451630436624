import React, { useEffect } from "react";
import {
  useExchangeRates,
  useFetchSpotAccount,
  useModal,
  useWalletConnector,
} from "../../hooks";
import { Details, RedeemSchedule } from "./components";
import "./style.scss";
import { Config } from "../../constants/env.config";
import {
  getCurrentChainId,
  getExternalWalletChainId,
} from "../../redux/blockchain/selectors";
import { useSelector } from "react-redux";
import { SpotAccountModal } from "../../components/spot-account-modal";

export const SpotAccountPage: React.FC = () => {
  const { walletConnected } = useWalletConnector();
  const { modalVisible, toggleModalVisibility, closeModal } = useModal();

  const isProd = Config.ENV === "PRODUCTION";

  const chainId = useSelector(getCurrentChainId) as SupportedChainId;

  const { spotAccountDetails } = useFetchSpotAccount();
  const { selectedCurrencyPrice } = useExchangeRates(
    spotAccountDetails.currency as DefaultWalletCurrencies
  );

  useEffect(() => {
    if (walletConnected && isProd && chainId === 11155111) {
      toggleModalVisibility();
    } else if (walletConnected && !isProd && chainId === 1) {
      toggleModalVisibility();
    }
  }, [chainId, isProd, walletConnected]);

  return (
    <div className="spot-account">
      <div className="spot-account-sections">
        <Details
          redeemable={spotAccountDetails.availableRedeem}
          currency={spotAccountDetails.currency}
          balance={spotAccountDetails.balance}
          redeemDate={spotAccountDetails.nearestRedeemableDate}
          currencyPrice={selectedCurrencyPrice}
        />
        <RedeemSchedule
          investments={spotAccountDetails.investments}
          currencyPrice={selectedCurrencyPrice}
        />
      </div>

      {walletConnected && (
        <SpotAccountModal isOpen={modalVisible} onCloseModal={closeModal} />
      )}
    </div>
  );
};
