import { envService } from "../services/env.service";

export const ETH_DECIMALS = 18 as const;
export const C_ETH_DECIMALS = 8 as const;
export const USDT_DECIMALS = 6 as const;

// TODO: Add metax addresses

export const ETH_TOKENS: Record<
  EthNetwork,
  Record<ERC20DefaultTokens, ITokenBase>
> = {
  mainnet: {
    METAX: {
      symbol: "METAX",
      decimals: ETH_DECIMALS,
      chainId: 1,
      address: "0x055028aA0d8e075593d60e98BBAA5f08b480C1D8",
    },
    EPAN: {
      symbol: "EPAN",
      decimals: ETH_DECIMALS,
      chainId: 1,
      address: "0x72630B1e3B42874bf335020Ba0249e3E9e47Bafc",
    },
    POLVEN: {
      symbol: "POLVEN",
      decimals: ETH_DECIMALS,
      chainId: 1,
      address: "0x4a6ab9792e9f046c3ab22d8602450de5186be9a7",
    },
    cETH: {
      address: "0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5",
      symbol: "cEth",
      decimals: C_ETH_DECIMALS,
      chainId: 1,
    },
    "UNI-V2": {
      address: "0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc",
      symbol: "UNI-V2",
      decimals: ETH_DECIMALS,
      chainId: 1,
    },
    WETH: {
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      symbol: "WETH",
      decimals: ETH_DECIMALS,
      chainId: 1,
    },
    USDT: {
      symbol: "USDT",
      decimals: USDT_DECIMALS,
      chainId: 1,
      address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    },
  },
  sepolia: {
    METAX: {
      symbol: "METAX",
      decimals: ETH_DECIMALS,
      chainId: 3,
      address: "0x055028aA0d8e075593d60e98BBAA5f08b480C1D8",
    },
    EPAN: {
      symbol: "EPAN",
      decimals: ETH_DECIMALS,
      chainId: 3,
      address: "0x7243465DA6b5614bea5Ec5114ce183C94ec0f8Ea",
    },
    POLVEN: {
      symbol: "POLVEN",
      decimals: ETH_DECIMALS,
      chainId: 3,
      address: "0x7D31733d65C1c29E49bA2767F0C08d9AAa6F12A3",
    },
    cETH: {
      address: "0x859e9d8a4edadfedb5a2ff311243af80f85a91b8",
      symbol: "cEth",
      decimals: C_ETH_DECIMALS,
      chainId: 3,
    },
    "UNI-V2": {
      address: "0x8cad25b511ceeff2f20fea5825a6993113aa8211",
      symbol: "UNI-V2",
      decimals: ETH_DECIMALS,
      chainId: 3,
    },
    WETH: {
      address: "0xc778417e063141139fce010982780140aa0cd5ab",
      symbol: "WETH",
      decimals: ETH_DECIMALS,
      chainId: 1,
    },
    USDT: {
      symbol: "USDT",
      decimals: USDT_DECIMALS,
      chainId: 3,
      address: "0xaDB10bC25C620C9affdEF85a3ceb973a9F0A58dD",
    },
  },
} as const;

export const BSC_TOKENS: Record<
  BscNetwork,
  Record<BEP20DefaultTokens, ITokenBase>
> = {
  bscMainnet: {
    BEPAN: {
      symbol: "BEPAN",
      decimals: ETH_DECIMALS,
      chainId: 56,
      address: "0x985E2a89c2e0C1AFB56f699cf320Da0782FE0e51",
    },
    WBNB: {
      symbol: "WBNB",
      decimals: ETH_DECIMALS,
      chainId: 56,
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    BAKE: {
      symbol: "BAKE",
      decimals: ETH_DECIMALS,
      chainId: 97,
      address: "0xe02df9e3e622debdd69fb838bb799e3f168902c5",
    },
  },
  bscTestnet: {
    BEPAN: {
      symbol: "BEPAN",
      decimals: ETH_DECIMALS,
      chainId: 97,
      address: "0x584885E251253CB9670dFDC6B40Ef45b91db9eC2",
    },
    WBNB: {
      symbol: "WBNB",
      decimals: ETH_DECIMALS,
      chainId: 97,
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    },
    BAKE: {
      symbol: "BAKE",
      decimals: ETH_DECIMALS,
      chainId: 97,
      address: "0xe02df9e3e622debdd69fb838bb799e3f168902c5",
    },
  },
} as const;

export const STAGE_ETH_TOKENS: Record<
  EthNetwork,
  Record<ERC20DefaultTokens, ITokenBase>
> = {
  mainnet: ETH_TOKENS.mainnet,
  sepolia: {
    EPAN: {
      ...ETH_TOKENS.sepolia.EPAN,
      address: "0x7243465DA6b5614bea5Ec5114ce183C94ec0f8Ea",
    },
    POLVEN: {
      ...ETH_TOKENS.sepolia.POLVEN,
      address: "0x7D31733d65C1c29E49bA2767F0C08d9AAa6F12A3",
    },
    USDT: {
      ...ETH_TOKENS.sepolia.USDT,
    },
    cETH: {
      ...ETH_TOKENS.sepolia.cETH,
      address: "0x859e9d8a4edadfedb5a2ff311243af80f85a91b8",
    },
    "UNI-V2": {
      address: "0x8cad25b511ceeff2f20fea5825a6993113aa8211",
      symbol: "UNI-V2",
      decimals: ETH_DECIMALS,
      chainId: 3,
    },
    WETH: {
      address: "0xc778417e063141139fce010982780140aa0cd5ab",
      symbol: "WETH",
      decimals: ETH_DECIMALS,
      chainId: 1,
    },
    METAX: {
      ...ETH_TOKENS.sepolia.METAX,
      address: "0x055028aA0d8e075593d60e98BBAA5f08b480C1D8",
    },
  },
} as const;

export const STAGE_BSC_TOKENS: Record<
  BscNetwork,
  Record<BEP20DefaultTokens, ITokenBase>
> = {
  bscMainnet: BSC_TOKENS.bscMainnet,
  bscTestnet: {
    BEPAN: {
      ...BSC_TOKENS.bscTestnet.BEPAN,
      address: "0x51c149cbdbd25C034a05D83ce04CC9F697f99590",
    },
    WBNB: BSC_TOKENS.bscTestnet.WBNB,
    BAKE: BSC_TOKENS.bscTestnet.BAKE,
  },
} as const;

export const DEFAULT_TOKEN_ADDRESSES: string[] = [
  ETH_TOKENS.mainnet.EPAN.address.toLowerCase(),
  ETH_TOKENS.mainnet.POLVEN.address.toLowerCase(),
  ETH_TOKENS.sepolia.EPAN.address.toLowerCase(),
  ETH_TOKENS.sepolia.POLVEN.address.toLowerCase(),
  STAGE_ETH_TOKENS.sepolia.EPAN.address.toLowerCase(),
  STAGE_ETH_TOKENS.sepolia.POLVEN.address.toLowerCase(),
  BSC_TOKENS.bscMainnet.BEPAN.address.toLowerCase(),
  BSC_TOKENS.bscTestnet.BEPAN.address.toLowerCase(),
  STAGE_BSC_TOKENS.bscTestnet.BEPAN.address.toLowerCase(),
  ETH_TOKENS.sepolia.METAX.address.toLowerCase(),
];

export const TOKEN_ICON_URLS: Record<DefaultWalletCurrencies, string> = {
  EPAN: "https://media-web-app-prod.s3.eu-central-1.amazonaws.com/epan-token-icon.svg",
  POLVEN:
    "https://media-web-app-prod.s3.eu-central-1.amazonaws.com/polven-token-icon.svg",
  BEPAN:
    "https://media-web-app-prod.s3.eu-central-1.amazonaws.com/epan-token-icon.svg",
  USDT: "",
  BNB: "",
  ETH: "",
  METAX: "",
};
