import { translate } from "../../api";
import {
  getPaypolitanStakingTimeframeDaysLeft,
  getPaypolitanStakingTimeframeLastDate,
  roundToDecimals,
} from "../../helpers";

export const getYearEarnings = (amount: number, apy: number) => {
  if (Number.isNaN(Number(amount)) || Number(amount) === 0) {
    return "0.00";
  }

  return roundToDecimals((amount * apy) / 100);
};

export const checkEarningType = (
  earningsBalances: IEarningWithBalance[],
  wallet: IWalletWithBalance,
  type: "long" | "flexible"
) => {
  return earningsBalances.find(
    (item) =>
      item.address === wallet.address && item.type.toLowerCase().includes(type)
  );
};

interface IGetPaypolitanStakingTokensDataParams {
  earningsBalances: IEarningWithBalance[];
  walletsBalances?: IWalletWithBalance[];
  flexibleApy?: string;
  longTermApy?: string;
  handlePressStake: (
    period: EpanStakingPeriod,
    selectedAddress: string,
    walletType: string
  ) => () => void;
  handlePressWithdraw: (
    period: EpanStakingPeriod,
    selectedAddress: string,
    walletType: string
  ) => () => void;
}

export const getPaypolitanStakingTokensData = ({
  earningsBalances,
  walletsBalances,
  handlePressStake,
  handlePressWithdraw,
  flexibleApy,
  longTermApy,
}: IGetPaypolitanStakingTokensDataParams) => [
  ...(walletsBalances
    ? walletsBalances
        .filter((wallet) => wallet.currency !== "BNB")
        .filter(
          (wallet) =>
            earningsBalances.filter((item) => {
              const type = item.type.toLowerCase();
              return (
                item.address === wallet.address &&
                (type.includes("long") || type.includes("flexible"))
              );
            }).length < 2
        )
        .map<ITokenItemProps>((wallet: any) => {
          const isLongTerm = checkEarningType(earningsBalances, wallet, "long");
          const isFlexible = checkEarningType(
            earningsBalances,
            wallet,
            "flexible"
          );

          return {
            type: wallet.currency !== "BNB" ? wallet.currency : "BEPAN", // TODO needs redesign of this block of code
            walletType: wallet.type,
            walletName: wallet.name ?? translate("internal"),
            secondColumnValue: longTermApy,
            thirdColumnValue: flexibleApy,
            bottomButtons: [
              ...(!isLongTerm
                ? [
                    {
                      label: translate("epan-staking:staking-period-long"),
                      onPress: handlePressStake(
                        "long",
                        wallet.address,
                        wallet.type
                      ),
                    },
                  ]
                : []),
              ...(!isFlexible
                ? [
                    {
                      label: translate("epan-staking:staking-period-flexible"),
                      onPress: handlePressStake(
                        "flexible",
                        wallet.address,
                        wallet.type
                      ),
                    },
                  ]
                : []),
            ],
          };
        })
    : []),
  ...earningsBalances.map<ITokenItemProps>((earning) => {
    const balance = Number(earning.balance) ?? 0;
    const investedAmount = Number(earning.investedAmount ?? 0);
    const stakeDaysLeft = getPaypolitanStakingTimeframeDaysLeft(
      earning.lastStakeTime ?? ""
    );
    const endsStackingDate =
      getPaypolitanStakingTimeframeLastDate(stakeDaysLeft);
    const isStakingEnded = stakeDaysLeft <= 0;
    const isLongTerm =
      earning.type === "epanStakingLong" ||
      earning.type === "polvenStakingLong" ||
      earning.type === "bepanStakingLong";
    const balInvestedAmountDif = balance - investedAmount;
    const earnings = balInvestedAmountDif > 0 ? balInvestedAmountDif : 0;
    const apy = earning.apy ?? 0;
    const walletType = earning.walletType;

    return {
      endsStackingDate,
      isStakingEnded,
      isLongTerm,
      walletType,
      type: earning.currency,
      investedAmount,
      secondColumnValue: `${apy.toFixed(2)} %`,
      thirdColumnValue: roundToDecimals(investedAmount),
      fourthColumnValue: roundToDecimals(earnings),
      fifthColumnValue: roundToDecimals(balance),
      walletName: earning.name ?? translate("internal"),
      lastStakeTime: earning.lastStakeTime ?? "",
      bottomLabel: isLongTerm
        ? translate("staking-period-long", "epan-staking")
        : translate("staking-period-flexible", "epan-staking"),
      bottomButtons: [
        {
          label: translate("staking:top-up"),
          onPress: handlePressStake(
            isLongTerm ? "long" : "flexible",
            earning.address,
            walletType
          ),
        },
        {
          label: translate("staking:redeem"),
          disabled: isLongTerm && !isStakingEnded,
          onPress: handlePressWithdraw(
            isLongTerm ? "long" : "flexible",
            earning.address,
            walletType
          ),
        },
      ],
    };
  }),
];
