import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEthNetwork } from "../../helpers";
import { getCurrentNetwork } from "../../redux/blockchain/selectors";

export const useScreen = () => {
  const network = useSelector(getCurrentNetwork);
  const [filters, setFilters] = useState<TTxFiltersState>({
    coin: isEthNetwork(network) ? "METAX" : "BEPAN",
  });

  const onSelectFilter = useCallback((type: TTxFilterType, value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  }, []);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        coin: isEthNetwork(network) ? "METAX" : "BEPAN",
      };
    });
  }, [network]);

  return {
    filters,
    onSelectFilter,
  };
};
