import React from 'react';
import { CommonButton } from '../../../../components';
import { isEthNetwork } from '../../../../helpers';
import EtherscanLogo from '../../../../assets/images/icons/etherscan-logo.svg';
import BscScanLogo from '../../../../assets/images/icons/bsc-scan-logo.svg';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export const ViewTxButton = (props: IProps) => {
  const { t } = useTranslation(['common']);
  return (
    <CommonButton
      containerClassName={props.containerClass}
      startIcon={
        <img
          className="view-tx-button-icon"
          src={isEthNetwork(props.network) ? EtherscanLogo : BscScanLogo}
          alt="logo"
        />
      }
      onClick={props.onScanClick}
      label={t(
        isEthNetwork(props.network)
          ? 'transactions-history:view-on-eth-scan'
          : 'transactions-history:view-on-bsc-scan',
      )}
    />
  );
};
