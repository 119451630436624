import React, { useCallback } from "react";
import "./styles.scss";
import { CommonTileContainer } from "../common-tile-container";
import { useTranslation } from "react-i18next";
import { cropMetaString } from "../../helpers";
import { CommonButton } from "../common-button";
import { AvatarUser } from "../user-avatar";
import { WalletSelect } from "../wallet-select";
import {
  useConnectionWalletModal,
  useUserInfo,
  useWalletConnector,
} from "../../hooks";
import { useLocation } from "react-router-dom";
import { routes } from "../../constants";
import { providersIconMap } from "../connect-wallet-modal/use-modal";
import { useSelector } from "react-redux";
import { getExternalWalletData } from "../../redux/blockchain/selectors";

export const DashboardHeader = () => {
  const { t } = useTranslation();
  const { firstName, lastName, photo } = useUserInfo();
  const location = useLocation();
  const walletData = useSelector(getExternalWalletData);
  const { openModal: openConnectionWalletModal } = useConnectionWalletModal();
  const {
    connectedAddress,
    walletConnecting,
    walletConnected,
    chainIdSupported,
    externalWalletType,
  } = useWalletConnector();
  const userData = {
    lastName,
    firstName,
    photoUrl: photo,
  };

  const renderUserName = useCallback(() => {
    switch (location.pathname) {
      case routes.DASHBOARD:
        return (
          <p className="dashboard-header-greetings">
            {t("dashboard:hello", {
              name: `${firstName} ${lastName}`,
            })}
          </p>
        );
      case routes.STAKING:
        return (
          <p className="dashboard-header-greetings">
            {t("staking:main-title")}
          </p>
        );
      case routes.TRANSACTION_HISTORY:
        return (
          <p className="dashboard-header-greetings">
            {t("transactions-history:main-title")}
          </p>
        );
      case routes.CROSS_CHAIN_BRIDGE:
        return (
          <p className="dashboard-header-greetings">
            {t("cross-chain-bridge:main-title")}
          </p>
        );
      case routes.SPOT_ACCOUNT:
      case routes.REDEEM_SPOT_ACCOUNT:
      case routes.TOP_UP_SPOT_ACCOUNT:
        return (
          <p className="dashboard-header-greetings">
            {t("spot-account:main-title")}
          </p>
        );
      case routes.BUY_METAX:
      case routes.BUY_METAX_BUY:
        return (
          <p className="dashboard-header-greetings">
            {t("spot-account:buy-metax")}
          </p>
        );
      case routes.SMART_CREDIT:
        return (
          <p className="dashboard-header-greetings">
            {t("drawer:smart-credit")}
          </p>
        );
      /*       case routes.TRANSACTION_HISTORY:
        return <p className="dashboard-header-greetings">{t('transactions-history:main-title')}</p>; */
      default:
        return null;
    }
  }, [firstName, t, lastName, location.pathname]);

  const renderWallet = useCallback(() => {
    if (walletConnected || !chainIdSupported) {
      return (
        <>
          <WalletSelect />
          <div className="dashboard-header-wallet-info dashboard-header-row">
            <div className="dashboard-header-user-wallet-item">
              <div className="dashboard-header-row">
                <img
                  src={providersIconMap[externalWalletType] ?? ""}
                  alt="metaicon"
                />
                <span className="dashboard-header-wallet-name">
                  {walletData?.name}
                </span>
              </div>
              {connectedAddress && (
                <button
                  onClick={openConnectionWalletModal}
                  className="dashboard-header-address-button"
                >
                  <span className="dashboard-header-wallet-address">
                    {cropMetaString(connectedAddress)}
                  </span>
                </button>
              )}
            </div>
          </div>
        </>
      );
    }
    if (!walletConnected) {
      return (
        <CommonButton
          disabled={walletConnecting}
          onClick={openConnectionWalletModal}
          size="large"
          label={t("common:connect-wallet")}
        />
      );
    }
  }, [
    walletData,
    chainIdSupported,
    openConnectionWalletModal,
    connectedAddress,
    t,
    walletConnected,
    walletConnecting,
    externalWalletType,
  ]);

  return (
    <div className="dashboard-header">
      {renderUserName()}
      <CommonTileContainer className="dashboard-header-user">
        {renderWallet()}
        <AvatarUser userInfo={userData} />
      </CommonTileContainer>
    </div>
  );
};
