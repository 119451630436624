import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { paypolitanStakingService, web3Service } from "../services/blockchain";
import { getEarningsBalancesBlockchainError } from "../redux/blockchain/actions";
import { useCallback } from "react";
import {
  __DEV__,
  DEFAULT_REFETCH_INTERVAL,
  EARNING_AMOUNT_PRECISION,
  METAX_BALANCE_PRECISION,
  paypolitanEarningToCurrencyMap,
} from "../constants";
import { useWalletConnector } from "./use-wallet-connector";

export const EARNING_BALANCES_CACHE_KEY = "EARNING_BALANCES_CACHE_KEY";

const getEarningBalance = async (earning: IEarning) => {
  switch (earning.type) {
    // case "epanStakingLong":
    // case "polvenStakingLong":
    case "bepanStakingLong":
      return paypolitanStakingService.getStakeBalance(
        paypolitanEarningToCurrencyMap[earning.type],
        true,
        earning.address
      );
    // case "epanStakingFlexible":
    // case "polvenStakingFlexible":
    case "bepanStakingFlexible":
      return paypolitanStakingService.getStakeBalance(
        paypolitanEarningToCurrencyMap[earning.type],
        false,
        earning.address
      );
    default:
      return null;
  }
};

const getInvestedAmount = async (
  earning: IEarning,
  infoPromise: Promise<Maybe<IPaypolitanStakeTimeframeResponse>>
) => {
  const info = await infoPromise;

  if (info?.amount) {
    return web3Service.formatBalanceFromWei(
      info?.amount ?? "0",
      "ether",
      EARNING_AMOUNT_PRECISION
    );
  }

  return null;
};

const getEarningApy = async (earning: IEarning) => {
  switch (earning.type) {
    // case "epanStakingLong":
    // case "polvenStakingLong":
    case "bepanStakingLong":
      return paypolitanStakingService.getApy(
        paypolitanEarningToCurrencyMap[earning.type],
        true
      );
    // case "epanStakingFlexible":
    // case "polvenStakingFlexible":
    case "bepanStakingFlexible":
      return paypolitanStakingService.getApy(
        paypolitanEarningToCurrencyMap[earning.type],
        false
      );
    default:
      return 0;
  }
};

// const getEarningReward = async (earning: IEarning) => {
//   switch (earning.type) {
//     case 'compound':
//       return null;
//     case 'epanStakingLong':
//       return paypolitanStakingService.getRewardAmount('EPAN', true, earning.address);
//     case 'epanStakingFlexible':
//       return paypolitanStakingService.getRewardAmount('EPAN', false, earning.address);
//     case 'polvenStakingLong':
//       return paypolitanStakingService.getRewardAmount('POLVEN', true, earning.address);
//     case 'polvenStakingFlexible':
//       return paypolitanStakingService.getRewardAmount('POLVEN', false, earning.address);
//     default:
//       return null;
//   }
// };

const getStakesInfo = async (earning: IEarning) => {
  switch (earning.type) {
    // case "epanStakingLong":
    // case "polvenStakingLong":
    case "bepanStakingLong":
      return paypolitanStakingService.getStakesInfo(
        paypolitanEarningToCurrencyMap[earning.type],
        earning.address,
        true
      );
    // case "epanStakingFlexible":
    // case "polvenStakingFlexible":
    case "bepanStakingFlexible":
      return paypolitanStakingService.getStakesInfo(
        paypolitanEarningToCurrencyMap[earning.type],
        earning.address,
        false
      );
    default:
      return null;
  }
};

export const useEarningsBalances = (earnings: IEarning[]) => {
  const dispatch = useDispatch();
  const { walletEnabled } = useWalletConnector();

  const {
    data: earningsBalances,
    isLoading: isLoadingEarningsBalances,
    refetch: refetchEarningsBalances,
  } = useQuery<IEarning[], Error, IEarningWithBalance[]>(
    [EARNING_BALANCES_CACHE_KEY, earnings],
    () => {
      const promises = earnings.map<Promise<IEarningWithBalance>>(
        async (earning) => {
          // const stakeInfoPromise = getStakesInfo(earning);

          return {
            ...earning,
            reward: null /*await getEarningReward(earning)*/, // not used currently
            balance: await getEarningBalance(earning),
            apy: await getEarningApy(earning),
            investedAmount: (await getStakesInfo(earning))?.amount ?? null,
            lastStakeTime:
              (await getStakesInfo(earning))?.lastStakeTime ?? null,
          };
        }
      );

      return Promise.all<IEarningWithBalance>(promises).then((earnings) => {
        return earnings.map((earning) => ({
          ...earning,
          investedAmount: web3Service.formatBalanceFromWei(
            earning.investedAmount ?? "0",
            "ether",
            METAX_BALANCE_PRECISION
          ),
        }));
      });
    },
    {
      enabled: walletEnabled,
      staleTime: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      cacheTime: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      refetchInterval: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      retry: 2,
      onError: (e) => {
        dispatch(getEarningsBalancesBlockchainError(e));
      },
    }
  );

  const onRefreshEarningBalances = useCallback(() => {
    refetchEarningsBalances();
  }, [refetchEarningsBalances]);

  const positiveEarnings = (earningsBalances ?? []).filter((earning) => {
    return earning.balance && parseFloat(earning.balance) > 0;
  });

  return {
    earningsBalances: positiveEarnings,
    isLoadingEarningsBalances,
    onRefreshEarningBalances,
  };
};
