import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { useEffect } from 'react';
import { routes } from '../../constants';

export const useFiatPaymentsInfo = () => {
  const location = useLocation();
  const query = queryString.parse(decodeURIComponent(location.search));
  const history = useHistory();

  useEffect(() => {
    if (!query?.iban && !query?.accountNumber) {
      history.push(routes.SIGN_IN);
    }
  }, [query?.link]);

  return {
    ...(query?.amount ? { amount: query?.amount } : {}),
    ...(query?.iban ? { ibanNumber: query?.iban } : {}),
    ...(query?.bic ? { bicCode: query?.bic } : {}),
    ...(query?.bankName ? { bankName: query?.bankName } : {}),
    ...(query?.recipientName ? { recipientName: query?.recipientName } : {}),
    ...(query?.accountNumber ? { accountNumber: query?.accountNumber } : {}),
    ...(query?.currency ? { currency: query?.currency } : {}),
    ...(query?.type ? { type: query?.type } : {}),
    fiatAmount: `${query?.amount ?? ''} ${query?.currency ?? ''}`,
  };
};
