import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton, PageContentContainer } from "../../../../components";
import check from "../../../../assets/images/icons/check.svg";
import cross from "../../../../assets/images/icons/cross.svg";
import growth from "../../../../assets/images/icons/growth_arrow.svg";
import decline from "../../../../assets/images/icons/decline_arrow.svg";
import "./style.scss";
import { FIAT_EXCHANGE_PRECISION, routes } from "../../../../constants";
import cx from "classnames";
import { ListHeader } from "../list-header";
import { useScreen } from "../../use-screen";
import { useFilterData } from "../../use-filter-data";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentFiatCurrency } from "../../../../redux/users/selectors";
import { formatDate, getTime } from "../../../../utils/formatDate";

export const RedeemSchedule = (props: IScheduleProps) => {
  const { t } = useTranslation(["spot-account"]);
  const { onSelectFilter, filters } = useScreen();
  const history = useHistory();
  const filterData = useFilterData();
  const selectedFiatCurrency = useSelector(getCurrentFiatCurrency);

  const shouldInvestmentRender = useMemo(() => {
    switch (filters.show) {
      case "all":
        return (val: ISpotAccountInvestmentAsset) => val;
      case "investments":
        return (val: ISpotAccountInvestmentAsset) => val.isBonus === false;
      case "bonuses":
        return (val: ISpotAccountInvestmentAsset) => val.isBonus === true;
      default:
        return (val: ISpotAccountInvestmentAsset) =>
          [true, false].includes(val.isBonus!);
    }
  }, [filters.show]);

  return (
    <section className="spot-account-section spot-account-section-fill">
      <div className="spot-account-section-item spot-account-section-item-overflown">
        <div className="spot-account-schedule">
          <div className="spot-account-schedule-header">
            <ListHeader
              selectedFilters={filters}
              filtersDisabled={false}
              onSelectFilter={onSelectFilter}
              filtersData={filterData}
            />
          </div>
          <PageContentContainer className="spot-account-schedule-list">
            {!props.investments || props.investments.length === 0 ? (
              <div className="spot-account-schedule-list-empty-indicator">
                <span>{t("emptyRedeemSchedule")}</span>
              </div>
            ) : (
              props.investments
                .filter((val) => shouldInvestmentRender(val))
                .map((val) => {
                  const isRedeemable =
                    Date.now() >= getTime(val.redeemableDate);
                  const exchangeRate = val.exchangeRate.find(
                    (e) => e.currency === selectedFiatCurrency.currency
                  );

                  const isGrowth =
                    Number(exchangeRate?.currentPrice ?? 0) >=
                    Number(exchangeRate?.initialPrice ?? 0);

                  return (
                    <div
                      className="spot-account-schedule-list-item"
                      key={val.id}
                    >
                      <div className="spot-account-schedule-list-item-left">
                        <span className="spot-account-schedule-list-item-label spot-account-schedule-list-item-investment-label">
                          {t(val.isBonus ? "bonus" : "investment")}
                        </span>
                        <div className="spot-account-schedule-list-item-value">
                          {Number(val.value).toFixed(FIAT_EXCHANGE_PRECISION)}{" "}
                          <span className="spot-account-schedule-list-item-value">
                            {val.currency}
                          </span>
                          {" / "}
                          {Number(exchangeRate?.initialPrice ?? 0).toFixed(
                            FIAT_EXCHANGE_PRECISION
                          )}
                          &nbsp;
                          {selectedFiatCurrency.currency}
                        </div>
                      </div>
                      <div className="spot-account-schedule-list-item-right">
                        <div>
                          <div className="spot-account-schedule-list-item-right-info-item">
                            <span className="spot-account-schedule-list-item-label spot-account-schedule-list-item-right-item-label">
                              {t("redeemable")}
                            </span>
                            <div className="spot-account-schedule-list-item-right-info-item-value-container">
                              <span className="spot-account-schedule-list-item-value spot-account-schedule-list-item-right-item-value spot-account-schedule-redeemable-date">
                                {formatDate({
                                  date: val.redeemableDate as string,
                                  format: "DD.MM.YYYY hh:mm",
                                })}
                              </span>
                              <img
                                src={isRedeemable ? check : cross}
                                alt={"icon"}
                              />
                            </div>
                          </div>
                          <div className="spot-account-schedule-list-item-right-info-item">
                            <span className="spot-account-schedule-list-item-label spot-account-schedule-list-item-right-item-label">
                              {t("current-value")}
                            </span>
                            <div className="spot-account-schedule-list-item-right-info-item-value-container">
                              <span className="spot-account-schedule-list-item-value spot-account-schedule-list-item-right-item-value">
                                {Number(
                                  exchangeRate?.currentPrice ?? 0
                                ).toFixed(FIAT_EXCHANGE_PRECISION)}{" "}
                                <span className="spot-account-schedule-list-item-value">
                                  {selectedFiatCurrency.currency}
                                </span>
                              </span>
                              <img
                                className="spot-account-schedule-list-item-right-item-value-indicator"
                                alt={"icon"}
                                src={isGrowth ? growth : decline}
                              />
                              <span
                                className={cx(
                                  "spot-account-schedule-list-item-value",
                                  isGrowth
                                    ? "spot-account-schedule-list-item-right-item-growth"
                                    : "spot-account-schedule-list-item-right-item-decline"
                                )}
                              >
                                {Number(exchangeRate?.difference ?? 0)}%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="spot-account-schedule-redeem">
                          <CommonButton
                            labelClassName="spot-account-schedule-redeem-button-label"
                            className="spot-account-schedule-redeem-button"
                            label={t("redeem")}
                            size="small"
                            disabled={!isRedeemable}
                            onClick={() =>
                              history.push(
                                `${routes.REDEEM_METAX}?transactionId=${val.transactionId}&amount=${val.value}`
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </PageContentContainer>
        </div>
      </div>
    </section>
  );
};
