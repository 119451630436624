import { FormikHelpers, FormikProps } from "formik";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  FETCH_SPOT_ACCOUNT_CACHE_KEY,
  useCurrentUserWalletsBalances,
  useFetchSpotAccount,
  useModal,
  useUserInfo,
  useWalletConnector,
  WALLETS_BALANCES_CACHE_KEY,
} from "../../hooks";
import { spotAccountResource } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTopUpRateFailure,
  fetchTopUpWalletAddressesFailure,
  redeemSpotAccountFundsFailure,
  topUpSpotAccountFailure,
} from "../../redux/spot-account/actions";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import {
  chainIdToNetworkMap,
  routes,
  TOKEN_DECIMAL_LENGTH,
} from "../../constants";
import Decimal from "decimal.js";
import {
  getMinInputAmountByCurrency,
  getRoundedBalance,
  getTopUpSpotAccountLockDate,
  isEthNetwork,
} from "../../helpers";
import { getDestinationAddress, getFiatAmount, payWithGasMap } from "./helpers";
import { getCurrentChainId } from "../../redux/blockchain/selectors";
import {
  resetSpotTopUpProcessToast,
  showConfirmedTransactionToast,
  showSpotTopUpProcessToast,
} from "../../redux/toast/actions";
import { reactQueryService, snackbarService } from "../../services";
import { EAbortControllers, web3Service } from "../../services/blockchain";
import { envService } from "../../services/env.service";
import { TransactionReceipt } from "web3-core";
const CALCULATE_RATE_AND_AMOUNT_CACHE_KEY =
  "CALCULATE_RATE_AND_AMOUNT_CACHE_KEY";
const GET_TOP_UP_WALLET_ADDRESSES_CACHE_KEY =
  "GET_TOP_UP_WALLET_ADDRESSES_CACHE_KEY";

const initCalculateRateParams: ICalculateRateQueryParams = {
  inputType: "pay",
  initialAmount: "1",
  payWith: "ETH",
  calculatedIn: "TOKEN",
  promoCode: undefined,
} as const;

export const useForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<ITopUpSpotAccountFormData> | null>(null);
  const history = useHistory();
  const { t } = useTranslation(["spot-account"]);
  const chainId = useSelector(getCurrentChainId);
  const network = chainIdToNetworkMap[chainId];
  const { spotAccountDetails } = useFetchSpotAccount();

  const initialValues: ITopUpSpotAccountFormData = {
    payWithAmount: "",
    payWithCurrency: "ETH",
    receiveAmount: "",
    receiveCurrency: spotAccountDetails?.currency as ReceiveCurrencyType,
    deadline: 20,
    slippage: 0.1,
    isInsufficientETHForPayFee: false,
  };

  const {
    modalVisible: confirmModalVisible,
    closeModal: closeConfirmModal,
    toggleModalVisibility: toggleConfirmModalVisibility,
  } = useModal();

  const {
    modalVisible: settingsModalVisible,
    closeModal: closeSettingsModal,
    toggleModalVisibility: toggleSettingsModalVisibility,
  } = useModal();

  const [confirmModalValues, setConfirmModalValues] =
    useState<Maybe<IConfirmTopUpSpotModalData>>(null);
  const [rate, setRate] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(25 * 1000);
  const { connectedAddress } = useWalletConnector();
  const selectedWallet = useMemo<Maybe<IWalletWithBalance>>(
    () =>
      connectedAddress
        ? {
            currency: "METAX" as const,
            address: connectedAddress,
            type: "metamaskExtension" as const,
            balance: null,
          }
        : null,
    [connectedAddress]
  );

  const { walletsBalances } = useCurrentUserWalletsBalances({
    currency: formRef.current?.values
      ?.payWithCurrency as DefaultWalletCurrencies,
  });

  if (selectedWallet) {
    selectedWallet.balance = walletsBalances?.length
      ? walletsBalances[0].balance
      : null;
  }

  const [calculateRateQueryParams, setCalculateRateQueryParams] =
    useState<ICalculateRateQueryParams>(initCalculateRateParams);
  const { userId } = useUserInfo();
  const { data: calculateRateAndAmountData, refetch: refetchRate } = useQuery<
    ICalculateRateAndAmountResponse | undefined,
    Error
  >(
    [
      CALCULATE_RATE_AND_AMOUNT_CACHE_KEY,
      calculateRateQueryParams,
      initialLoad,
      discountApplied,
    ],
    () =>
      spotAccountResource.calculateRateAndAmount({
        payWith: calculateRateQueryParams.payWith,
        calculatedIn: calculateRateQueryParams.calculatedIn,
        initialAmount: calculateRateQueryParams.initialAmount,
        ...(discountApplied
          ? { promoCode: calculateRateQueryParams.promoCode }
          : {}),
      }),
    {
      refetchOnMount: "always",
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
      enabled: Boolean(
        calculateRateQueryParams.initialAmount &&
          !Number.isNaN(calculateRateQueryParams.initialAmount) &&
          new Decimal(calculateRateQueryParams.initialAmount).toNumber() >=
            getMinInputAmountByCurrency(
              formRef.current?.values?.payWithCurrency
            )
      ),
      refetchInterval,
      onSuccess: (resp) => {
        if (resp) {
          setRate(
            new Decimal(resp.tokenRate)
              .dividedBy(resp.epanRate)
              .dividedBy(1 - (resp.promoCodePercentage ?? 0) / 100)
              .toDecimalPlaces(2)
              .toString()
          );

          if (initialLoad) {
            return;
          }

          setRefetchInterval(
            Math.floor((resp.tokenExpiresAt - new Date().valueOf()) * 0.8)
          );
          formRef.current?.setFieldValue(
            calculateRateQueryParams.inputType === "pay"
              ? "receiveAmount"
              : "payWithAmount",
            Number(resp.amount).toFixed(
              calculateRateQueryParams.inputType === "pay"
                ? 2
                : TOKEN_DECIMAL_LENGTH
            )
          );
        }
      },
      onError: (e) => {
        setDiscountApplied(false);
        dispatch(calculateTopUpRateFailure(e));
      },
    }
  );

  const handleTokenAmount = debounce(
    (type: InputType, amount: string, values: ITopUpSpotAccountFormData) => {
      if (initialLoad) {
        setInitialLoad(false);
        refetchRate();
      }
      if (amount && !Number.isNaN(Number(amount))) {
        setCalculateRateQueryParams({
          inputType: type,
          initialAmount: parseFloat(amount).toString(),
          payWith: values.payWithCurrency,
          calculatedIn: type === "pay" ? "TOKEN" : "EPAN",
          // promoCode: (discountApplied && values.discount) || undefined,
        });
      } else {
        setCalculateRateQueryParams(initCalculateRateParams);
        setInitialLoad(true);
        formRef.current?.setFieldValue(
          type === "pay" ? "receiveAmount" : "payWithAmount",
          ""
        );
      }
    },
    500
  );

  const rateText = calculateRateAndAmountData
    ? `1 ${calculateRateAndAmountData.payWith} = ${rate} EPAN`
    : "";

  const promoText = calculateRateAndAmountData
    ? t("spot-account:promo-code-valid", {
        discount: calculateRateAndAmountData.promoCodePercentage,
      })
    : "";

  const lockDate = getTopUpSpotAccountLockDate(
    calculateRateAndAmountData?.lockPeriod
  );
  const lockText = lockDate
    ? t("spot-account:promo-lock-period", { lockDate })
    : t("spot-account:no-lock-period");

  const { data: topUpWalletsAddresses } = useQuery<
    IGetTopUpWalletAddressesResponse | undefined,
    Error
  >(
    [GET_TOP_UP_WALLET_ADDRESSES_CACHE_KEY],
    () => spotAccountResource.getTopUpWalletAddresses(),
    {
      keepPreviousData: true,
      onError: (e) => {
        dispatch(fetchTopUpWalletAddressesFailure(e));
      },
    }
  );

  const onSubmitForm = useCallback(
    async (
      values: ITopUpSpotAccountFormData,
      { setSubmitting, resetForm }: FormikHelpers<ITopUpSpotAccountFormData>
    ) => {
      try {
        // if (values.discount && !discountApplied) {
        //   snackbarService.showErrorSnackbar(
        //     t("spot-account:not-applied-discount")
        //   );
        //   return;
        // }

        const topUpSpotModalData: IConfirmTopUpSpotModalData = {
          pay: `${values.payWithAmount} ${
            values.payWithCurrency
          } / ${getFiatAmount(
            values.payWithAmount,
            calculateRateAndAmountData?.tokenRate
          )} $`,
          receive: `${values.receiveAmount} ${
            values.receiveCurrency
          } / ${getFiatAmount(
            values.receiveAmount,
            calculateRateAndAmountData?.epanRate
          )} $`,
          rate: rateText,
          lockPeriod: getTopUpSpotAccountLockDate(
            calculateRateAndAmountData?.lockPeriod
          ),
          promoCode: calculateRateAndAmountData?.promoCode,
          discount: calculateRateAndAmountData?.promoCodePercentage
            ? `${calculateRateAndAmountData?.promoCodePercentage}%`
            : undefined,
          onClose: () => {
            setConfirmModalValues(null);
            closeConfirmModal();
          },
          onProceedPress: async () => {
            try {
              closeConfirmModal();
              setConfirmModalValues(null);
              setSubmitting(true);
              const toAddress = getDestinationAddress(
                values,
                topUpWalletsAddresses
              );
              const amount =
                calculateRateQueryParams.inputType === "receive"
                  ? Number(calculateRateAndAmountData?.amount ?? 0)
                  : Number(`${values.payWithAmount}`.replace(/,/g, "."));

              if (userId && toAddress && selectedWallet) {
                const onReceipt = async (trx?: TransactionReceipt) => {
                  resetForm();
                  dispatch(resetSpotTopUpProcessToast());
                  await reactQueryService.invalidateQueries(
                    FETCH_SPOT_ACCOUNT_CACHE_KEY
                  );
                  await reactQueryService.invalidateQueries(
                    WALLETS_BALANCES_CACHE_KEY
                  );
                  dispatch(
                    showConfirmedTransactionToast({
                      withAction: true,
                      message: t(
                        !trx || trx.status
                          ? "spot-top-up-success"
                          : "toast:failed-transaction-message",
                        {
                          amount: `${getRoundedBalance(
                            values.receiveAmount,
                            values.receiveCurrency
                          )} ${values.receiveCurrency}`,
                        }
                      ),
                    })
                  );
                  history.push(routes.BUY_METAX);
                };

                snackbarService.showWatingForApprovalTxSnackbar(
                  t("common:waiting-for-approval"),
                  "TOP_UP_SPOT_ACCOUNT",
                  () => {
                    web3Service.executeAbortToAbortController(
                      EAbortControllers.TOP_UP_SPOT_ACCOUNT
                    );
                    web3Service.clearAbortController(
                      EAbortControllers.TOP_UP_SPOT_ACCOUNT
                    );
                    snackbarService.closeSnackbar("TOP_UP_SPOT_ACCOUNT");
                  }
                );

                const txHash = await web3Service.sendTransaction({
                  executionAbortControllerName:
                    EAbortControllers.TOP_UP_SPOT_ACCOUNT,
                  toAddress,
                  amount,
                  estimatedGas: payWithGasMap[values.payWithCurrency],
                  userId,
                  fromWallet: selectedWallet,
                  chainId,
                  onReceipt,
                  customToken:
                    values.payWithCurrency === "USDT" && isEthNetwork(network)
                      ? envService.getEthTokens(network).USDT
                      : undefined,
                });
                if (txHash) {
                  dispatch(
                    showSpotTopUpProcessToast({
                      amount: topUpSpotModalData.pay,
                      network,
                      txHash: txHash,
                      type: "spotTopUpProcess",
                      wallet: selectedWallet,
                      onReceipt,
                      message: t("transaction-pending"),
                    })
                  );
                  await spotAccountResource.topUp({
                    token: calculateRateAndAmountData?.token ?? "",
                    transactionHash: txHash,
                  });
                }
              }

              setSubmitting(false);
            } catch (e: any) {
              dispatch(topUpSpotAccountFailure(e));
              dispatch(resetSpotTopUpProcessToast());
              setSubmitting(false);
            } finally {
              web3Service.clearAbortController(
                EAbortControllers.TOP_UP_SPOT_ACCOUNT
              );
              snackbarService.closeSnackbar("TOP_UP_SPOT_ACCOUNT");
            }
          },
        };
        setConfirmModalValues(topUpSpotModalData);
        toggleConfirmModalVisibility();
      } catch (e) {
        dispatch(topUpSpotAccountFailure(e as Error));
      } finally {
        setSubmitting(false);
      }
    },
    [
      toggleConfirmModalVisibility,
      dispatch,
      history,
      calculateRateAndAmountData,
      calculateRateQueryParams.inputType,
      chainId,
      dispatch,
      network,
      rateText,
      t,
      topUpWalletsAddresses,
      userId,
    ]
  );

  const onPressApplyDiscount = useCallback(
    (values: ITopUpSpotAccountFormData) => () => {
      // if (values.discount) {
      //   setCalculateRateQueryParams((prev) => ({
      //     ...prev,
      //     promoCode: values.discount,
      //   }));
      //   setDiscountApplied(true);
      // }
    },
    []
  );

  return {
    initialValues,
    onSubmitForm,
    confirmModalValues,
    confirmModalVisible,
    discount: calculateRateAndAmountData?.promoCodePercentage,
    closeConfirmModal,
    settingsModalVisible,
    closeSettingsModal,
    toggleSettingsModalVisibility,
    formRef,
    handleTokenAmount,
    refetchRate,
    setCalculateRateQueryParams,
    onPressApplyDiscount,
    discountApplied,
    setDiscountApplied,
    promoText,
    lockText,
  };
};
