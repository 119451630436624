import * as Yup from "yup";
import { SchemaOf } from "yup";
import {
  getAmountWithCurrencyYupValidation,
  getAmountYupValidation,
} from "../../helpers";

export const getValidationSchema = (
  maxAmount?: number | string,
  currency?: string,
  walletConnected?: boolean
) =>
  Yup.object({
    // selectedWallet: Yup.object().optional(),
    payWithAmount: getAmountWithCurrencyYupValidation({
      maxValue: maxAmount,
      currency,
      walletConnected,
    }),
    payWithCurrency: Yup.string(),
    receiveAmount: getAmountWithCurrencyYupValidation({
      currency: "EPAN",
      walletConnected,
    }),
    receiveCurrency: Yup.string(),
    deadline: Yup.number(),
    slippage: Yup.number(),
    // isInsufficientETHForPayFee: Yup.boolean(),
  });
