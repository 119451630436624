import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AmountField,
  CommonButton,
  CurrentStakingTable,
  FormTextField,
  PageContentContainer,
} from "../../components";
import "./style.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useStakingForm } from "./use-staking-form";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { FormikProps } from "formik/dist/types";
import { useScreen } from "./use-screen";
import { getPaypolitanStakingTokensData, getYearEarnings } from "./helper";
import {
  getCurrentNetwork,
  getExternalWalletData,
} from "../../redux/blockchain/selectors";
import {
  useConnectionWalletModal,
  useCurrentUserWalletsBalances,
  usePaypolitanStakingAllowance,
  useResetFormOnWalletDisconnection,
  useWalletConnector,
  WALLETS_BALANCES_CACHE_KEY,
} from "../../hooks";
import { useCurrentUserEarningsBalances } from "../../hooks/use-current-user-earnings-balances";
import {
  getMaxAmount,
  isEthNetwork,
  isTokenApprovalMandatory,
  roundToDecimals,
  thousandSeparator,
} from "../../helpers";
import { getValidationSchema } from "./validation-schema";
import { ReviewModal, StackingPeriodInfoModal } from "./components";
import { CurrencySelector } from "./components/currency-selector";
import { ETH_DECIMALS } from "../../constants";
import { reactQueryService } from "../../services";

export const StakingPage = () => {
  const { t } = useTranslation(["staking"]);
  const network = useSelector(getCurrentNetwork);
  const externalWalletData = useSelector(getExternalWalletData);
  const [currency, setCurrency] = useState<PaypolitanStakingType>("METAX");
  const { formRef } = useResetFormOnWalletDisconnection<IStakingFormData>();
  const [amount, setAmount] = useState("");
  const formikRef = useRef<Maybe<FormikProps<IStakingFormData>>>(null);
  const amountFieldRef = useRef<Maybe<HTMLInputElement>>(null);
  const { openModal: openConnectionWalletModal } = useConnectionWalletModal();
  const { connectedAddress, walletConnected, isChainSupported } =
    useWalletConnector();
  const selectedWallet = useMemo<Maybe<IWalletWithBalance>>(
    () =>
      connectedAddress
        ? {
            currency: "METAX" as const,
            address: connectedAddress,
            type: "metamaskExtension" as const,
            balance: null,
          }
        : null,
    [connectedAddress]
  );

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldValue("amount", "");
      formRef.current?.setTouched({
        amount: false,
      });
    });
  }, [currency, network, formRef]);

  const { walletsBalances } = useCurrentUserWalletsBalances({
    currency,
  });

  if (selectedWallet) {
    selectedWallet.balance = walletsBalances?.length
      ? walletsBalances[0].balance
      : null;
  }

  const { stakingAllowance } = usePaypolitanStakingAllowance(
    currency,
    selectedWallet
  );
  const {
    handleSelectDefiStakingAction,
    defiStakingAction,
    epanStakingPeriod,
    stakingApyDisplayValue,
    handleSelectEpanStakingPeriod,
    closeStackingPeriodModal,
    longPeriodWithdrawDaysLeft,
    processingToastVisible,
    stakingApy,
    longTermSelected,
    longTermLocked,
  } = useScreen(currency, amountFieldRef);

  const { earningsBalances, isLoadingEarningsBalances } =
    useCurrentUserEarningsBalances({
      selectedWallet: selectedWallet ?? undefined,
      currency,
    });

  const currentWalletBalance = selectedWallet ? selectedWallet.balance ?? 0 : 0;

  const currentEarning = earningsBalances.find((earning) => {
    if (epanStakingPeriod === "flexible" && currency === "METAX") {
      return earning.type === "epanStakingFlexible";
    } else if (epanStakingPeriod === "long" && currency === "METAX") {
      return earning.type === "epanStakingLong";
    }
    // else if (epanStakingPeriod === "flexible" && currency === "POLVEN") {
    //   return earning.type === "polvenStakingFlexible";
    // } else if (epanStakingPeriod === "long" && currency === "POLVEN") {
    //   return earning.type === "polvenStakingLong";
    // }
    // else if (epanStakingPeriod === "flexible" && currency === "BEPAN") {
    //   return earning.type === "bepanStakingFlexible";
    // } else if (epanStakingPeriod === "long" && currency === "BEPAN") {
    //   return earning.type === "bepanStakingLong";
    // }

    return false;
  });
  const currentEarningBalance = Number(currentEarning?.balance ?? 0);

  const maxAmount = useMemo(() => {
    if (defiStakingAction === "stake") {
      return getMaxAmount(currentWalletBalance, stakingAllowance ?? 0);
    } else {
      return currentEarningBalance;
    }
  }, [
    currentEarningBalance,
    currentWalletBalance,
    defiStakingAction,
    stakingAllowance,
  ]);

  const validationMaxAmount =
    defiStakingAction === "stake"
      ? Number(currentWalletBalance)
      : currentEarningBalance;

  useEffect(() => {
    if (
      !isLoadingEarningsBalances &&
      currentEarningBalance <= 0 &&
      defiStakingAction === "withdraw"
    ) {
      handleSelectDefiStakingAction(
        "stake",
        selectedWallet
      )(epanStakingPeriod)();
    }
  }, [
    selectedWallet,
    currentEarning,
    isLoadingEarningsBalances,
    currentEarningBalance,
    defiStakingAction,
    epanStakingPeriod,
    handleSelectDefiStakingAction,
  ]);

  useEffect(() => {
    formikRef.current?.setTouched({ amount: false });
  }, [defiStakingAction, epanStakingPeriod]);

  const {
    initialValues,
    onSubmitForm,
    closeApproveModal,
    approveModalValues,
    approveModalVisible,
    handleErc20Approve,
    approveLoading,
  } = useStakingForm({
    currency,
    defiStakingAction,
    epanStakingPeriod,
    selectedWallet,
  });

  const stakingData = getPaypolitanStakingTokensData({
    earningsBalances,
    handlePressStake: handleSelectDefiStakingAction("stake", selectedWallet),
    handlePressWithdraw: handleSelectDefiStakingAction(
      "withdraw",
      selectedWallet
    ),
  });

  const validationSchema = useMemo(
    () => getValidationSchema(validationMaxAmount),
    [validationMaxAmount]
  );
  const onChangeAmount = useCallback((value: string) => {
    if (!Number.isNaN(Number(value))) {
      setAmount(value);
    }
  }, []);

  const onChangeCurrency = useCallback(
    async (currency: PaypolitanStakingType) => {
      setCurrency(currency);
      await reactQueryService.invalidateQueries(WALLETS_BALANCES_CACHE_KEY);
    },
    []
  );

  const isApproveVisible =
    defiStakingAction === "stake" &&
    isTokenApprovalMandatory(
      Number(stakingAllowance ?? 0),
      amount,
      ETH_DECIMALS
    );

  return (
    <div className={cx("staking")}>
      <span className={cx("staking-title")}>
        {t(defiStakingAction === "stake" ? "new-staking" : "redeem-staking")}
      </span>
      <PageContentContainer>
        <span className={cx("staking-main-text")}>
          {t(
            defiStakingAction === "stake"
              ? "available-balance"
              : "current-staking"
          )}
        </span>
        <div className={cx("staking-balance-wrapper")}>
          {defiStakingAction === "stake" && (
            <span className={cx("staking-available-balance")}>
              {currentWalletBalance
                ? `${thousandSeparator(
                    roundToDecimals(currentWalletBalance)
                  )} ${currency}`
                : "-- / --"}
            </span>
          )}
          {defiStakingAction === "withdraw" && (
            <span className={cx("staking-available-balance")}>
              {currentEarningBalance
                ? `${roundToDecimals(currentEarningBalance)} ${currency}`
                : "-- / --"}
            </span>
          )}
          <CurrencySelector
            currency={currency}
            onCurrencyChange={onChangeCurrency}
            disabled={!walletConnected}
          />
        </div>
        <span className={cx("staking-secondary-text", "staking-amount-label")}>
          {t("common:enter-amount")}
        </span>
        <Formik<IStakingFormData>
          validateOnMount
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {({ isValid, values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className={cx("staking-row")}>
                <FormTextField<IStakingFormData>
                  name="amount"
                  placeholder={"0.00"}
                  disabled={!walletConnected}
                  inputRef={amountFieldRef}
                  onChangeText={onChangeAmount}
                  rightComponent={
                    <span className={cx("staking-main-text")}>{currency}</span>
                  }
                />
                <div className={cx("staking-spacer")} />
                <CommonButton
                  label={t("common:use-max")}
                  onClick={() => {
                    onChangeAmount(String(maxAmount));
                    setFieldValue("amount", maxAmount);
                  }}
                />
              </div>
              <div className={cx("staking-row", "staking-term-buttons")}>
                <CommonButton
                  fullWidth
                  className={cx("staking-checkbox-button", {
                    "staking-checkbox-button-selected": longTermSelected,
                  })}
                  labelClassName={cx("staking-checkbox-button-label")}
                  startIcon={
                    <div
                      className={cx("staking-bullet-wrapper", {
                        "staking-bullet-selected": longTermSelected,
                      })}
                    >
                      {longTermSelected && (
                        <div className={cx("staking-bullet")} />
                      )}
                    </div>
                  }
                  disabled={longTermLocked}
                  onClick={handleSelectEpanStakingPeriod("long")}
                  label={t("long-term")}
                />
                <div className={cx("staking-spacer")} />
                <CommonButton
                  fullWidth
                  className={cx("staking-checkbox-button", {
                    "staking-checkbox-button-selected": !longTermSelected,
                  })}
                  labelClassName={cx("staking-checkbox-button-label")}
                  onClick={handleSelectEpanStakingPeriod("flexible")}
                  startIcon={
                    <div
                      className={cx("staking-bullet-wrapper", {
                        "staking-bullet-selected": !longTermSelected,
                      })}
                    >
                      {!longTermSelected && (
                        <div className={cx("staking-bullet")} />
                      )}
                    </div>
                  }
                  label={t("flexible")}
                />
              </div>
              <div className={cx("staking-row")}>
                <span
                  className={cx("staking-main-text", "staking-term-redeem")}
                >
                  {t("redeem-in-six-months")}
                </span>
                <div className={cx("staking-spacer")} />
                <span
                  className={cx("staking-main-text", "staking-term-redeem")}
                >
                  {t("redeem-at-any-moment")}
                </span>
              </div>
              <Divider className={cx("staking-divider")} />
              {defiStakingAction === "stake" && (
                <AmountField
                  label={t("estimated-yearly-earnings")}
                  amount={`${getYearEarnings(
                    Number(values.amount),
                    stakingApy
                  )} ${currency}`}
                />
              )}
              <AmountField
                label={t("estimated-apy")}
                amount={stakingApyDisplayValue}
              />
              {isApproveVisible && walletConnected && (
                <span className="staking-unlimited-approval-info">
                  {t("common:unlimited-approval-info")}
                </span>
              )}
              {isChainSupported && (
                <div className={cx("staking-row", "staking-submit-buttons")}>
                  {!walletConnected && (
                    <CommonButton
                      type={"button"}
                      label={t("common:connect-wallet")}
                      onClick={openConnectionWalletModal}
                    />
                  )}
                  {walletConnected && (
                    <CommonButton
                      type={"submit"}
                      fullWidth
                      label={t(
                        defiStakingAction === "stake"
                          ? "common:lock"
                          : "epan-staking:withdraw"
                      )}
                      disabled={
                        !isValid ||
                        processingToastVisible ||
                        isSubmitting ||
                        isApproveVisible
                      }
                      loading={isSubmitting}
                    />
                  )}
                  {isApproveVisible && walletConnected && (
                    <>
                      <div className={cx("staking-spacer")} />
                      <CommonButton
                        fullWidth
                        type={"button"}
                        design="negative"
                        onClick={handleErc20Approve()}
                        label={t("common:approve")}
                        disabled={
                          !isValid || approveLoading || processingToastVisible
                        }
                        loading={approveLoading}
                      />
                    </>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
        {approveModalValues && (
          <ReviewModal
            onDismiss={closeApproveModal}
            visible={approveModalVisible}
            {...approveModalValues}
          />
        )}
        <StackingPeriodInfoModal
          visible={longPeriodWithdrawDaysLeft !== 0}
          onClose={closeStackingPeriodModal}
          daysLeft={longPeriodWithdrawDaysLeft}
        />
      </PageContentContainer>
      {walletConnected && !!stakingData?.length && (
        <div className={cx("staking-current")}>
          <span className={cx("staking-title")}>
            {t("staking:current-staking")}
          </span>
          <PageContentContainer>
            <CurrentStakingTable
              externalWalletData={externalWalletData}
              data={stakingData}
            />
          </PageContentContainer>
        </div>
      )}
    </div>
  );
};
