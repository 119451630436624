import React, { useMemo } from "react";
import "./style.scss";
import cx from "classnames";
import {
  CommonTileContainer,
  ContractIconRounded,
} from "../../../../components";
import moment from "moment";
import _ from "lodash";
import { AvatarUser } from "../../../../components/user-avatar";
import { useTranslation } from "react-i18next";

export const TxItem: React.FC<ITxItemProps> = ({
  className,
  onClick,
  transactionType,
  ...values
}) => {
  const { t } = useTranslation(["transactions-history"]);
  const participant = useMemo(
    () => (transactionType === "sent" ? values.recepient : values.sender),
    [transactionType, values]
  );
  const amount = values.amount
    ? +values.amount > 0
      ? Number(values.amount).toFixed(6)
      : 0
    : Number(values.fee).toFixed(6);

  return (
    <button
      onClick={onClick(values)}
      className={cx("tx-item-button-wrapper", className)}
    >
      <CommonTileContainer className={cx("tx-item")}>
        <div className="tx-item-row tx-item-header">
          <div className="tx-item-row">
            <ContractIconRounded contract={values.smartContract!.type} />
            <p className="tx-item-main-text tx-item-contract-label">
              {values.smartContract?.label.includes("SPOT_ACCOUNT")
                ? t(values.smartContract?.label.toString().toLowerCase()!)
                : values.smartContract?.label}
            </p>
          </div>
          {_.values(participant).some((v) => !_.isEmpty(v)) && (
            <div className="tx-item-row">
              <AvatarUser
                userInfo={{
                  firstName: participant.firstName,
                  lastName: participant.lastName,
                  photoUrl: participant.photo,
                }}
              />
              <span className="tx-item-participant-detail">
                {participant.firstName} {participant.lastName}
              </span>
            </div>
          )}
        </div>
        <div className="tx-item-row tx-item-header">
          <p className="tx-item-main-text tx-item-value">
            <span className="tx-item-value-highlight">{amount}</span>{" "}
            {values.currency} /{" "}
            <span className="tx-item-value-highlight">
              {values.fiatValue === "NaN" ? 0 : values.fiatValue}
            </span>{" "}
            {values.fiatCurrency}
          </p>
          <p className="tx-item-date">
            {moment(parseInt(values.date!) * 1000).calendar(null, {
              sameDay: "[Today] HH:mm",
              nextDay: "[Tomorrow] HH:mm",
              nextWeek: "DD.MM.YYYY HH:mm",
              lastDay: "[Yesterday] HH:mm",
              lastWeek: "DD.MM.YYYY HH:mm",
              sameElse: "DD.MM.YYYY HH:mm",
            })}
          </p>
        </div>
      </CommonTileContainer>
    </button>
  );
};
