import React, { useCallback, useMemo } from "react";
import { IStackingEarningProps } from "./types";
import cx from "classnames";
import "./style.scss";
import { CommonTileContainer, TokenIconRounded } from "../../../../components";
import { useTranslation } from "react-i18next";
import { getStakingAmountFieldData } from "./helper";

export const StakingEarning: React.FC<IStackingEarningProps> = ({
  type,
  earnings,
  walletConnected,
}) => {
  const { t } = useTranslation(["staking"]);

  const getAmountField = useCallback(
    (label: string, value: string | number, idx: number) => {
      return (
        <div
          key={`${label}${value}${idx}`}
          className={cx("staking-earning-row", "staking-earning-amount-field")}
        >
          <span
            className={cx("staking-earning-main-text", "staking-earning-label")}
          >
            {t(label)}
          </span>
          <span className={cx("staking-earning-main-text")}>{value}</span>
        </div>
      );
    },
    [t]
  );

  const data = useMemo(
    () => getStakingAmountFieldData({ earnings, type, walletConnected }),
    [earnings, type, walletConnected]
  );

  return (
    <CommonTileContainer>
      <div className={cx("staking-earning-row")}>
        <TokenIconRounded tokenName={type} />
        <span className={cx("staking-earning-token-name")}>{type}</span>
      </div>
      <div className={cx("staking-earning-content")}>
        {data.map((item, idx) => {
          return getAmountField(item.label, item.value, idx);
        })}
      </div>
    </CommonTileContainer>
  );
};
