import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFilters } from "./use-filters";
import "./style.scss";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/blackArrow.svg";
import { isNull } from "../../../../utils/isNull";

export const ListHeader: React.FC<ITxListHeaderProps> = ({
  filtersDisabled,
  filtersData = [],
  selectedFilters,
  onSelectFilter,
}) => {
  const { t } = useTranslation(["transactions-history"]);
  const {
    anchorEl,
    handleCloseFilterDropdown,
    handleOpenFilterDropdown,
    onFilterClick,
  } = useFilters(filtersData, onSelectFilter);

  return (
    <div className="tx-list-header">
      <span className="tx-list-header-title">{t("list-of-transactions")}</span>
      {filtersData.map((item, idx) => {
        const currentAnchorEl = anchorEl?.[item.type as string];
        const selectedFilterLabel =
          item.filters.find(
            (filter) => filter.value === selectedFilters[item.type]
          )?.label ?? "";

        return (
          <div
            className="tx-list-header-filters-wrapper"
            key={`${item.type}${idx}`}
          >
            <div className="tx-list-header-filter-item">
              <span className="tx-list-header-filter-item-title">{`${t(
                item.title
              )}:`}</span>
              <Button
                disabled={!!filtersDisabled}
                aria-haspopup="true"
                onClick={handleOpenFilterDropdown(item.type)}
                aria-controls="simple-menu"
              >
                <div className="tx-list-header-filter-button-inner">
                  <span>{t(selectedFilterLabel)}</span>
                  <Arrow
                    className={`${
                      !isNull(currentAnchorEl) ? "rotate180" : "rotate0"
                    }`}
                  />
                </div>
              </Button>
            </div>
            <Menu
              keepMounted
              id="simple-menu"
              anchorEl={currentAnchorEl}
              onClose={handleCloseFilterDropdown}
              open={Boolean(currentAnchorEl)}
            >
              {item.filters.map((filter, idx) => (
                <MenuItem
                  key={`${filter.value}${filter.label}${idx}`}
                  onClick={onFilterClick(item.type, filter.value)}
                >
                  <div className="tx-list-header-filter-menu-item-icon">
                    {filter.icon}
                  </div>
                  <span>{t(filter.label)}</span>
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      })}
    </div>
  );
};
