import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TokenIconRounded } from "../../components";
import { BSC_TOKENS, ETH_TOKENS } from "../../constants";
import { isEthNetwork } from "../../helpers";
import { getCurrentNetwork } from "../../redux/blockchain/selectors";

export const useFilterData = (): ITxListHeaderFiltersData[] => {
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation("transaction-history");
  return [
    {
      type: "coin",
      title: "common:coin",
      filters: isEthNetwork(network)
        ? [
            {
              icon: <TokenIconRounded tokenName={"METAX"} />,
              label: ETH_TOKENS[network].METAX.symbol,
              value: ETH_TOKENS[network].METAX.symbol,
            },
            // {
            //   icon: <TokenIconRounded tokenName={"POLVEN"} />,
            //   label: ETH_TOKENS[network].POLVEN.symbol,
            //   value: ETH_TOKENS[network].POLVEN.symbol,
            // },
            {
              icon: <TokenIconRounded tokenName="ETH" />,
              label: "ETH",
              value: "ETH",
            },
            {
              icon: <TokenIconRounded tokenName="CUSTOM" />,
              label: t("custom-tokens"),
              value: "CUSTOM",
            },
          ]
        : [
            // {
            //   icon: <TokenIconRounded tokenName="BEPAN" />,
            //   label: BSC_TOKENS[network].BEPAN.symbol,
            //   value: BSC_TOKENS[network].BEPAN.symbol,
            // },
            {
              icon: <TokenIconRounded tokenName="BNB" />,
              label: "BNB",
              value: "BNB",
            },
            {
              icon: <TokenIconRounded tokenName="CUSTOM" />,
              label: t("custom-tokens"),
              value: "CUSTOM",
            },
          ],
    },
  ];
};
