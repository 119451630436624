import React, { useMemo } from "react";
import "./style.scss";
import cx from "classnames";
import { CommonCollapsedBlock } from "../../components";
import { calculateSpotInvestments, calculateTotalBalance } from "./helper";
import { useSelector } from "react-redux";
import {
  useCurrentUserWalletsBalances,
  useExchangeRates,
  useFetchSpotAccount,
  useWalletConnector,
} from "../../hooks";
import { getCurrentFiatCurrency } from "../../redux/users/selectors";
import { useCurrentUserEarningsBalances } from "../../hooks/use-current-user-earnings-balances";
import { isBscNetwork, isEthNetwork, noop } from "../../helpers";
import { getCurrentNetwork } from "../../redux/blockchain/selectors";
import { useTranslation } from "react-i18next";
import {
  EpanGraph,
  TotalBalance,
  StakingEarning,
  IndividualTokens,
  EpanPriceStatistics,
  SpotAccountInvestments,
  BankAccount,
} from "./components";
import { routes } from "../../constants";
import { useHistory } from "react-router-dom";

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation(["dashboard"]);
  const network = useSelector(getCurrentNetwork);
  const { currenciesQuotes } = useExchangeRates();
  const { walletConnected, isChainSupported } = useWalletConnector();
  const { earningsBalances } = useCurrentUserEarningsBalances();
  const { currency: fiatCurrency, symbol } = useSelector(
    getCurrentFiatCurrency
  );
  const { walletsBalances } = useCurrentUserWalletsBalances();
  const { spotAccountDetails } = useFetchSpotAccount();
  const history = useHistory();

  const investments = useMemo(
    () =>
      calculateSpotInvestments({
        currenciesQuotes,
        spotAccountDetails,
        fiatCurrency,
      }),
    [currenciesQuotes, spotAccountDetails, fiatCurrency]
  );

  const total = useMemo(
    () =>
      calculateTotalBalance({
        walletsBalances,
        earningsBalances,
        currenciesQuotes,
        spotAccountDetails,
        totalBalanceCurrencySign: symbol,
        inCurrency: fiatCurrency,
      }),
    [
      currenciesQuotes,
      earningsBalances,
      walletsBalances,
      fiatCurrency,
      symbol,
      spotAccountDetails,
    ]
  );

  return (
    <div className={cx("page-wrapper", "dashboard-content")}>
      <div
        className={cx(
          "dashboard-row",
          "dashboard-section",
          "dashboard-balances"
        )}
      >
        <div
          className={cx(
            "dashboard-one-third-wrapper",
            "dashboard-balance-item",
            "dashboard-item-wrapper"
          )}
        >
          <TotalBalance
            walletConnected={walletConnected}
            balance={total.totalBalance}
            currency={total.totalBalanceCurrencySign}
          />
        </div>
        <div className="dashboard-spacer" />
        <div
          className={cx(
            "dashboard-two-third-wrapper",
            "dashboard-token-balances-wrapper"
          )}
        >
          <span className="dashboard-token-balances">
            {t("token-balances")}
          </span>
          <div
            className={cx("dashboard-item-wrapper", "dashboard-balance-item")}
          >
            <IndividualTokens
              tokens={walletsBalances}
              isChainSupported={isChainSupported}
            />
          </div>
          <div className="dashboard-spacer" />
          <div
            className={cx("dashboard-item-wrapper", "dashboard-balance-item")}
          >
            <SpotAccountInvestments
              investments={investments}
              onTopUpSpotAccountClick={() => history.push(routes.BUY_METAX_BUY)}
              onRedeemClick={(transactionId, amount) =>
                history.push(
                  `${routes.REDEEM_METAX}?transactionId=${transactionId}&amount=${amount}`
                )
              }
            />
          </div>
        </div>
      </div>
      <div
        className={cx(
          "dashboard-row",
          "dashboard-section",
          "dashboard-staking-content"
        )}
      >
        <div className={cx("dashboard-one-third-wrapper")}>
          <CommonCollapsedBlock label={t("bank-accounts")}>
            <div
              className={cx(
                "dashboard-collapsed-content",
                "dashboard-bank-accounts-wrapper"
              )}
            >
              <div className={cx("dashboard-item-wrapper")}>
                <BankAccount />
              </div>
              <div className="dashboard-bank-account-spacer" />
              <div className={cx("dashboard-item-wrapper")}>
                <BankAccount />
              </div>
            </div>
          </CommonCollapsedBlock>
        </div>
        <div className={cx("dashboard-spacer")} />
        <div className="dashboard-two-third-wrapper">
          <CommonCollapsedBlock label={t("staking")}>
            <div
              className={cx(
                "dashboard-row",
                "dashboard-collapsed-content",
                "dashboard-collapsed-staking-content"
              )}
            >
              {isEthNetwork(network) && (
                <>
                  <div className="dashboard-item-wrapper">
                    <StakingEarning
                      type="METAX"
                      earnings={earningsBalances}
                      walletConnected={walletConnected}
                    />
                  </div>
                  <div className="dashboard-spacer" />
                  <div className="dashboard-item-wrapper">
                    {/* <StakingEarning
                      type="POLVEN"
                      earnings={earningsBalances}
                      walletConnected={walletConnected}
                    /> */}
                  </div>
                </>
              )}

              {isBscNetwork(network) && (
                <div className="dashboard-item-wrapper">
                  <StakingEarning
                    type="BEPAN"
                    earnings={earningsBalances}
                    walletConnected={walletConnected}
                  />
                </div>
              )}
            </div>
          </CommonCollapsedBlock>
        </div>
      </div>
      {/* <div className={cx("dashboard-graph", "dashboard-section")}>
        <CommonCollapsedBlock label={t("metabank-token")}>
          <EpanGraph />
        </CommonCollapsedBlock>
      </div>
      <CommonCollapsedBlock label={t("metabank-price-stats")}>
        <EpanPriceStatistics />
      </CommonCollapsedBlock> */}
    </div>
  );
};
