import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  CONFIGURE_CURRENT_CHAIN_ID,
  configureCurrentChainId,
  notSupportedChain,
  setCurrentChainId,
  setCurrentChainIdBlockchainError,
  setCurrentChainIdLoading,
  supportedChain,
} from "./actions";
import {
  ethBscBridgeService,
  paypolitanStakingService,
  web3Service,
} from "../../services/blockchain";
import {
  GET_PAYPOLITAN_STAKING_APY_CACHE_KEY,
  GET_ESTIMATED_GAS_CACHE_KEY,
  WALLETS_BALANCES_CACHE_KEY,
} from "../../hooks";
import { EARNING_BALANCES_CACHE_KEY } from "../../hooks/use-earnings-balances";
import { reactQueryService } from "../../services";
import { transactionsResource } from "../../api";
import { chainIdToNetworkMap, supportedChainIds } from "../../constants";
import { resetAllProcessToast } from "../toast/actions";

function* refetchBlockchainData() {
  const queries = [
    WALLETS_BALANCES_CACHE_KEY,
    EARNING_BALANCES_CACHE_KEY,
    GET_PAYPOLITAN_STAKING_APY_CACHE_KEY,
    GET_ESTIMATED_GAS_CACHE_KEY,
    transactionsResource.fetchTransactionCacheKey,
  ];
  yield reactQueryService.invalidateQueriesByPredicate((query) => {
    return queries.includes(query.queryKey[0] as string);
  });
}

function* configureCurrentChainIdSaga({
  payload,
}: ReturnType<typeof configureCurrentChainId>) {
  try {
    yield put(setCurrentChainIdLoading(true));

    const supportedChainId = supportedChainIds.includes(
      payload.chainId as SupportedChainId
    )
      ? (payload.chainId as SupportedChainId)
      : null;

    if (supportedChainId) {
      yield put(setCurrentChainId(supportedChainId));
      yield put(supportedChain());
      const network = chainIdToNetworkMap[supportedChainId];
      yield paypolitanStakingService.init(web3Service, network);
      yield ethBscBridgeService.init(web3Service, network);
    } else {
      yield put(notSupportedChain());
    }

    yield put(resetAllProcessToast());

    yield call(refetchBlockchainData);

    if (payload.onChangeCallback) {
      yield delay(500);
      yield call(payload.onChangeCallback);
    }
  } catch (e) {
    yield put(setCurrentChainIdBlockchainError(e as Error));
  } finally {
    yield put(setCurrentChainIdLoading(false));
  }
}

export function* watchBlockchainSaga() {
  yield takeLatest(CONFIGURE_CURRENT_CHAIN_ID, configureCurrentChainIdSaga);
}
