import { IStackingEarningProps } from "./types";
import { earningsApy, METAX_BALANCE_PRECISION } from "../../../../constants";
import Decimal from "decimal.js";
import { roundToDecimals } from "../../../../helpers";

export const getStakingAmountFieldData = ({
  type,
  earnings,
  walletConnected,
}: IStackingEarningProps) => {
  const currentEarnings = earnings.filter((e) => e.currency === type);
  const total = new Decimal(
    currentEarnings.reduce(
      (acc, cur) =>
        Number(acc) +
        Number(cur?.balance ?? 0) -
        Number(cur?.investedAmount ?? 0),
      0
    )
  )
    .toDecimalPlaces(METAX_BALANCE_PRECISION, Decimal.ROUND_HALF_FLOOR)
    .toNumber();
  const long = currentEarnings.find((e) => e.type.endsWith("Long"));
  const flexible = currentEarnings.find((e) => e.type.endsWith("Flexible"));

  return [
    {
      label: "earnings",
      value: `${walletConnected ? roundToDecimals(total) : "--/--"} ${type}`,
    },
    {
      label: "apy-long-term",
      value: `${
        long?.apy ||
        (type === "METAX"
          ? earningsApy.polvenStakingLong
          : earningsApy.epanStakingLong)
      } %`,
    },
    {
      label: "staked",
      value: `${
        walletConnected ? roundToDecimals(long?.investedAmount ?? 0) : "--/--"
      } ${type}`,
    },
    {
      label: "apy-flexible",
      value: `${
        flexible?.apy ||
        (type === "METAX"
          ? earningsApy.polvenStakingFlexible
          : earningsApy.epanStakingFlexible)
      } %`,
    },
    {
      label: "staked",
      value: `${
        walletConnected
          ? roundToDecimals(flexible?.investedAmount ?? 0)
          : "--/--"
      } ${type}`,
    },
  ];
};
