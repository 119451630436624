import React from "react";
import { IDashboardTotalBalanceProps } from "./types";
import TotalBalanceIcon from "../../../../assets/images/icons/total-balance-icon.svg";
import cx from "classnames";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { roundToDecimals, thousandSeparator } from "../../../../helpers";

export const TotalBalance: React.FC<IDashboardTotalBalanceProps> = ({
  balance,
  currency,
}) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <div className={cx("dashboard-total-balance")}>
      <img src={TotalBalanceIcon} alt={t("total-balance")} />
      {currency ? (
        <>
          <span className={cx("dashboard-total-balance-label")}>
            {t("total-balance")}
          </span>
          <span
            className={cx("dashboard-total-balance-value")}
          >{`${currency} ${thousandSeparator(roundToDecimals(balance))}`}</span>
        </>
      ) : (
        <span className={cx("dashboard-total-balance-label")}>
          {t("total-balance-empty")}
        </span>
      )}
    </div>
  );
};
