import { protectedApi } from "../decorators";
import { httpService } from "../../../services";
import { WALLET_CONNECT_TRANSACTION_TIMEOUT } from "../../../constants";

export const USER = "/users";
export const ACTIVATE_SPOT_ACCOUNT = "/users/spot-account-balances";
export const SPOT_ACCOUNT_TOTAL_BALANCE =
  "/spot-account-transactions/stats/total-balances";
export const REDEEM_SPOT_ACCOUNT = "/transfers/";
export const ESTIMATE_REDEEM_SPOT_ACCOUNT = `${REDEEM_SPOT_ACCOUNT}estimate`;
export const CALCULATE_RATE_AND_AMOUNT = "/spot-account-top-up/epan-amount";
export const TOP_UP_WALLET_ADDRESSES = "/spot-account-top-up/wallets";
export const SPOT_TOP_UP = "/spot-account-top-up/web";

export class SpotAccountResource {
  @protectedApi()
  async activate(data: IActivateSpotAccountParams) {
    return httpService.post<IActivateSpotAccountResponse>({
      url: ACTIVATE_SPOT_ACCOUNT,
      data,
    });
  }

  @protectedApi()
  async fetchSpotAccount() {
    const user = await httpService.get<IApiUser>({
      url: USER,
    });

    return user?.spotAccountBalances ?? [];
  }

  @protectedApi()
  async fetchSpotAccountTotalBalance() {
    return httpService.get<{ currency: string; amount: string }>({
      url: SPOT_ACCOUNT_TOTAL_BALANCE,
    });
  }

  @protectedApi()
  async calculateRateAndAmount(data: ICalculateRateAndAmountParams) {
    return httpService.post<ICalculateRateAndAmountResponse>({
      url: CALCULATE_RATE_AND_AMOUNT,
      data,
    });
  }

  @protectedApi()
  async topUpSpotAccount(data: ITopUpSpotAccountParams) {
    return httpService.post<unknown>({
      url: ACTIVATE_SPOT_ACCOUNT,
      data,
    });
  }

  @protectedApi()
  async redeemSpotAccountFunds(data: IRedeemSpotAccountFundsParams) {
    return httpService.post<IRedeemSpotAccountFundsResponse>({
      url: REDEEM_SPOT_ACCOUNT,
      timeout: WALLET_CONNECT_TRANSACTION_TIMEOUT,
      data,
    });
  }

  @protectedApi()
  async getTopUpWalletAddresses() {
    return httpService.get<IGetTopUpWalletAddressesResponse>({
      url: TOP_UP_WALLET_ADDRESSES,
    });
  }

  @protectedApi()
  async estimateGasForRedeemSpotAccount(
    params: IEstimateGasForRedeemSpotAccountParams
  ) {
    return httpService.get<IEstimateGasForRedeemSpotAccountResponse>({
      url: ESTIMATE_REDEEM_SPOT_ACCOUNT,
      params,
    });
  }
  @protectedApi()
  async topUp(data: ITopUpSpotAccountData) {
    return httpService.post<ICalculateRateAndAmountResponse>({
      url: SPOT_TOP_UP,
      data,
    });
  }
}

export const spotAccountResource = new SpotAccountResource();
