import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  CommonButton,
  FormTextField,
  PageContentContainer,
} from "../../components";
import { useConnectionWalletModal, useWalletConnector } from "../../hooks";
import notConnected from "../../assets/images/icons/not-connected.svg";
import wallet from "../../assets/images/icons/wallet.svg";
import "./style.scss";
import {
  getEllipsizeString,
  roundToDecimals,
  thousandSeparator,
} from "../../helpers";
import { useForm } from "./use-form";
import { ReactComponent as ArrowLeft } from "../../assets/images/icons/arrow.svg";
import { Form, Formik } from "formik";
import { getValidationSchema } from "./validation-schema";
import cx from "classnames";
import { useScreen } from "./use-screen";
import { providersIconMap } from "../../components/connect-wallet-modal/use-modal";
import { getExternalWalletData } from "../../redux/blockchain/selectors";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../constants";
import { ConfirmModal } from "./components";
import qs from "querystring";
import { Radio } from "@mui/material";

export const RedeemSpotAccountPage = () => {
  const { t } = useTranslation(["spot-account", "common"]);
  const location = useLocation();
  const history = useHistory();
  const walletData = useSelector(getExternalWalletData);
  const [transactionAmount, setTransactionAmount] = useState("");
  const { externalWalletType, connectedAddress, walletConnected } =
    useWalletConnector();
  const { walletAddressFocused, toggleWalletAddressFocus } = useScreen();
  const { openModal: openConnectionWalletModal } = useConnectionWalletModal();

  const {
    onSubmitForm,
    formRef,
    initialValues,
    confirmModalValues,
    confirmModalVisible,
    closeConfirmModal,
  } = useForm(walletConnected);

  useEffect(() => {
    const params = qs.parse(location.search.slice(1));
    const transactionId =
      (params as unknown as IWithdrawSpotAccountScreenNavigationParams)
        ?.transactionId ?? "";
    if (!transactionId) {
      history.push(routes.BUY_METAX);
    } else {
      const amount =
        (params as unknown as IWithdrawSpotAccountScreenNavigationParams)
          ?.amount ?? "";

      setTransactionAmount(amount);
    }
  }, [location]);

  return (
    <div>
      <div
        onClick={() => history.push(routes.BUY_METAX)}
        className="redeem-spot-account-back"
      >
        <ArrowLeft />
        <span className="redeem-spot-account-back-text">
          {t("back", { ns: "common" })}
        </span>
      </div>
      <div className="redeem-spot-account">
        <span className="redeem-spot-account-title">
          {t("redeem-from-spot")}
        </span>
        <span className="redeem-spot-account-description">
          <Trans
            i18nKey={"spot-account:redeem-from-spot-desc"}
            values={{
              transactionAmount: thousandSeparator(
                roundToDecimals(transactionAmount)
              ),
            }}
            components={{ bold: <b style={{ color: "black" }} /> }}
          />
        </span>
        <PageContentContainer>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={getValidationSchema(!walletConnected)}
            onSubmit={onSubmitForm}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="redeem-spot-account-wallet redeem-spot-account-wallet-connected">
                  <div className="redeem-spot-account-wallet-connected-indicator">
                    <Radio
                      checked={
                        walletConnected && values.walletType === "connected"
                      }
                      onChange={(_, checked) => {
                        if (checked && walletConnected) {
                          setFieldValue("walletType", "connected");
                        }

                        if (!walletConnected) {
                          openConnectionWalletModal();
                        }
                      }}
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#db3094",
                        },
                      }}
                    />
                    <span className="redeem-spot-account-wallet-title">
                      {t("redeem-to-wallet")}
                    </span>
                  </div>
                  <div
                    className={cx(
                      "redeem-spot-account-wallet-connected-widget",
                      "redeem-spot-account-wallet-widget",
                      {
                        "redeem-spot-account-wallet-connected-widget-padding":
                          walletConnected,
                      },
                      {
                        "redeem-spot-account-wallet-widget-focused":
                          values.walletType === "external",
                      }
                    )}
                  >
                    {walletConnected ? (
                      <>
                        <div className="redeem-spot-account-wallet-connected-widget-wallet-info">
                          <div className="redeem-spot-account-wallet-connected-widget-icon-container">
                            <img
                              src={providersIconMap[externalWalletType] ?? ""}
                              alt="metaicon"
                            />
                          </div>
                          <div className="redeem-spot-account-wallet-connected-widget-wallet-info-details">
                            <div className="redeem-spot-account-wallet-connected-widget-wallet-info-details-data">
                              <span className="redeem-spot-account-wallet-connected-widget-wallet-info-details-data-type">
                                {walletData?.name}
                              </span>
                              <span className="redeem-spot-account-wallet-connected-widget-wallet-info-details-data-address">
                                {getEllipsizeString(connectedAddress!)}
                              </span>
                            </div>
                            <div
                              className={cx(
                                "redeem-spot-account-wallet-connected-widget-wallet-info-details-change-wallet",
                                {
                                  "redeem-spot-account-wallet-connected-widget-wallet-info-details-change-wallet-disabled":
                                    values.walletType === "external",
                                }
                              )}
                            >
                              <span
                                // TODO refactor and make a button
                                onClick={
                                  values.walletType === "external"
                                    ? undefined
                                    : openConnectionWalletModal
                                }
                              >
                                {t("change-wallet")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="redeem-spot-account-not-connected">
                        <div className="redeem-spot-account-not-connected-info">
                          <img src={notConnected} />
                          <span className="redeem-spot-account-not-connected-info-text">
                            {t("not-connected")}
                          </span>
                        </div>
                        <div
                          className={cx(
                            "redeem-spot-account-wallet-connected-widget-wallet-info-details-change-wallet",
                            {
                              "redeem-spot-account-wallet-connected-widget-wallet-info-details-change-wallet-disabled":
                                values.walletType === "external",
                            }
                          )}
                        >
                          <span
                            // TODO refactor and make a button
                            onClick={
                              values.walletType === "external"
                                ? undefined
                                : openConnectionWalletModal
                            }
                          >
                            {t("connect-wallet")}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="redeem-spot-account-wallet">
                  <div className="redeem-spot-account-wallet-connected-indicator">
                    <Radio
                      checked={
                        values.walletType === "external" || !walletConnected
                      }
                      onChange={(_, checked) => {
                        if (checked) {
                          setFieldValue("walletType", "external");
                        }
                      }}
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#db3094",
                        },
                      }}
                    />
                    <span className="redeem-spot-account-wallet-title">
                      {t("redeem-to-external")}
                    </span>
                  </div>
                  <div
                    className={cx("redeem-spot-account-wallet-widget", {
                      "redeem-spot-account-wallet-widget-focused":
                        walletAddressFocused ||
                        values.walletType === "connected",
                    })}
                  >
                    <div className="redeem-spot-account-wallet-external-widget-head">
                      <img src={wallet} />
                      <span className="redeem-spot-account-wallet-external-widget-head-text">
                        {t("wallet-address")}
                      </span>
                    </div>
                    <div>
                      <FormTextField<IWithdrawSpotAccountFormData>
                        placeholder={t("paste-address")}
                        name={"wallet"}
                        className="ccb-input"
                        highlightOnFocus
                        onFocus={toggleWalletAddressFocus}
                        onBlur={toggleWalletAddressFocus}
                        disabled={values.walletType === "connected"}
                      />
                    </div>
                  </div>
                  <div className="redeem-spot-account-action-buttons">
                    <CommonButton
                      containerClassName="redeem-spot-account-action-button-cancel-container"
                      labelClassName="redeem-spot-account-action-button-label redeem-spot-account-action-button-cancel-label"
                      className="redeem-spot-account-action-button redeem-spot-account-action-button-cancel"
                      label={t("cancel", { ns: "common" })}
                      onClick={() => history.push(routes.BUY_METAX)}
                    />
                    <CommonButton
                      labelClassName="redeem-spot-account-action-button-label"
                      className="redeem-spot-account-action-button"
                      label={t("proceed", { ns: "common" })}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </PageContentContainer>
      </div>
      <ConfirmModal
        isVisible={confirmModalVisible}
        closeConfirmModal={closeConfirmModal}
        {...confirmModalValues}
      />
    </div>
  );
};
