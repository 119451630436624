import React from "react";
import "./styles.scss";
import metabankLogo from "./../assets/images/metabank-logo.svg";
import paypolitanLogo from "./../assets/images/paypolitan-logo-white.svg";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../constants";
import {
  NotFoundPage,
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
} from "../pages";
import { Config } from "../constants/env.config";

export const AuthRouter: React.FC = () => {
  const isMetabank = Config.REACT_APP_NAME === "METABANK";
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <div className="userAuth">
      <div
        className={
          isMetabank ? "userAuth-image-metabank" : "userAuth-image-paypolitan"
        }
      >
        <img
          className={
            isMetabank
              ? "userAuth-image-metabank-logo"
              : "userAuth-image-paypolitan-logo"
          }
          src={isMetabank ? metabankLogo : paypolitanLogo}
          alt="logo"
        />
        {/* {isMetabank && (
          <div
            className={
              isMetabank
                ? "userAuth-image-metabank-footer"
                : "userAuth-image-paypolitan-footer"
            }
          >
            <p
              className={
                isMetabank
                  ? "userAuth-image-metabank-footer-label"
                  : "userAuth-image-paypolitan-footer-label"
              }
            >
              Powered by
            </p>
            <img
              className={
                isMetabank
                  ? "userAuth-image-metabank-footer-image"
                  : "userAuth-image-paypolitan-footer-image"
              }
              src={paypolitanLogo}
            />
          </div>
        )} */}
      </div>
      <div className="userAuth-form">
        {isMobile && (
          <img
            className={
              isMetabank
                ? "userAuth-image-metabank-logo"
                : "userAuth-image-paypolitan-logo"
            }
            src={isMetabank ? metabankLogo : paypolitanLogo}
            alt="logo"
          />
        )}
        <Switch>
          <Route path={routes.SIGN_IN} component={SignInPage} />
          <Route path={routes.SIGN_UP} component={SignUpPage} />
          <Route path={routes.RESET_PASSWORD} component={ResetPasswordPage} />
          <Redirect exact from="/auth" to={routes.SIGN_IN} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
};
