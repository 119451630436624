import React, { useCallback } from "react";
import cx from "classnames";
import "./style.scss";
import WalletIcon from "../../../../assets/images/icons/wallet-icon.svg";
import { IIndividualTokensProps } from "./types";
import { useTranslation } from "react-i18next";
import MetamaskIcon from "../../../../assets/images/icons/metamask-icon.svg";
import CoinbaseIcon from "../../../../assets/images/icons/coinbase.svg";
import { Divider } from "@mui/material";
import { thousandSeparator } from "../../../../helpers";
import { NETWORK_NATIVE_CURRENCIES } from "../../../../constants";
import { useConnectionWalletModal } from "../../../../hooks";
import { web3Service } from "../../../../services/blockchain";
import { snackbarService } from "../../../../services";
import {
  CommonTileContainer,
  TokenIconRounded,
  CommonButton,
} from "../../../../components";
import { isNativeCurrency, roundToDecimals } from "../../../../helpers";
import { useSelector } from "react-redux";
import { getExternalWalletType } from "../../../../redux/blockchain/selectors";

export const IndividualTokens: React.FC<IIndividualTokensProps> = ({
  tokens = [],
  isChainSupported,
}) => {
  const { t } = useTranslation(["dashboard"]);
  const externalWalletType = useSelector(getExternalWalletType);
  const { openModal: openConnectionWalletModal } = useConnectionWalletModal();

  const addToMetamaskOrCoinbase = useCallback(
    (token: IWallet) => async () => {
      try {
        const added = await web3Service.addTokenToMetamaskOrCoinbase(token);

        if (!added) {
          snackbarService.showErrorSnackbar(
            t("add-wallet-failure", {
              token: token.currency,
              wallet: web3Service.web3.givenProvider?.selectedProvider
                ?.isCoinbaseWallet
                ? "Coinbase"
                : "Metamask",
            })
          );
        }
      } catch (e) {
        snackbarService.showErrorSnackbar(
          t("add-wallet-failure", {
            token: token.currency,
            wallet: externalWalletType === "coinbase" ? "Coinbase" : "Metamask",
          })
        );
      }
    },
    [t]
  );
  return (
    <CommonTileContainer>
      <span
        className={cx("individual-tokens-title", "individual-tokens-main-text")}
      >
        {t("individual-tokens")}
      </span>
      <div
        className={cx("individual-tokens-content", {
          "individual-tokens-empty-content": !tokens.length,
        })}
      >
        {tokens.length ? (
          <>
            {tokens.map((item, idx, arr) => {
              const isLastItem = idx === arr.length - 1;
              const balance = isNativeCurrency(item.currency)
                ? item.balance ?? "0"
                : roundToDecimals(item.balance ?? 0);
              return (
                <div key={`${item.address}-${item.currency}`}>
                  <div
                    key={`${item.balance}${idx}`}
                    className={cx("individual-tokens-item-wrapper")}
                  >
                    <TokenIconRounded tokenName={item.currency} />
                    <div className={cx("individual-tokens-item-content")}>
                      <span
                        className={cx(
                          "individual-tokens-secondary-text",
                          "individual-tokens-balance-label"
                        )}
                      >
                        {t("balance")}
                      </span>
                      <span
                        className={cx(
                          "individual-tokens-main-text",
                          "individual-tokens-balance-value"
                        )}
                      >
                        {`${thousandSeparator(balance)} ${
                          item.currency
                        } / ${roundToDecimals(item.fiatValue)} ${
                          item.fiatCurrency
                        }`}
                      </span>
                      {!NETWORK_NATIVE_CURRENCIES.includes(
                        item.currency as NetworkNativeCurrency
                      ) &&
                        externalWalletType === "metamaskExtension" && (
                          <div
                            className={cx("individual-tokens-metamask-wrapper")}
                          >
                            <img src={MetamaskIcon} alt={"Metamask"} />
                            <span
                              // TODO replace with button
                              onClick={addToMetamaskOrCoinbase(item)}
                              className={cx(
                                "individual-tokens-secondary-text",
                                "individual-tokens-add-to-metamask"
                              )}
                            >
                              {t("add-to-metamask")}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  {!isLastItem && (
                    <div className={cx("individual-tokens-divider")}>
                      <Divider />
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <img
              className={cx("individual-tokens-wallet-icon")}
              src={WalletIcon}
              alt={t("individual-tokens")}
            />
            <span className={cx("individual-tokens-disconnected-wallet-label")}>
              {t("wallet-is-not-connected")}
            </span>
            {isChainSupported && (
              <CommonButton
                design="greyish"
                label={t("common:connect-wallet")}
                onClick={openConnectionWalletModal}
              />
            )}
          </>
        )}
      </div>
    </CommonTileContainer>
  );
};
