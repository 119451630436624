import {
  blockExplorerType,
  blockExplorerUrl,
  NETWORK_NATIVE_CURRENCIES,
} from "../constants";
import Decimal from "decimal.js";
import qs, { ParsedUrlQuery } from "querystring";

export const isBscNetwork = (
  network: EthNetwork | BscNetwork
): network is BscNetwork => {
  return network === "bscMainnet" || network === "bscTestnet";
};

export const isEthNetwork = (
  network: EthNetwork | BscNetwork
): network is EthNetwork => {
  return !network || network === "mainnet" || network === "sepolia";
};

// export const getWalletConnectInfo = (connector: ReturnType<typeof useWalletConnect>) => ({
//   type: (connector?.peerMeta?.name?.toLowerCase() === 'metamask'
//     ? 'metamask'
//     : 'other') as IWalletType,
//   name: connector?.peerMeta?.name ?? '',
//   icon: connector?.peerMeta?.icons?.length ? connector.peerMeta.icons[0] : '',
// });

export const getMaxAmount = (
  balance: string | number,
  allowance: string | number
) => {
  return Number(allowance) === 0 || Number(allowance) > Number(balance)
    ? Number(balance)
    : Number(allowance);
};

export const isNativeCurrency = (currency: string) =>
  NETWORK_NATIVE_CURRENCIES.includes(currency as NetworkNativeCurrency);

export const isTokenApprovalMandatory = (
  allowance?: number,
  amount?: number | string,
  decimals?: number
) =>
  new Decimal(allowance || 0).lessThan(
    new Decimal(amount || 0).mul(10 ** (decimals || 18))
  );

export const getBlockExplorer = (network: Network): BlockExplorer => {
  let blockExplorer: BlockExplorer;
  Object.keys(blockExplorerType).map((val) => {
    if (blockExplorerType[val as BlockExplorer].includes(network)) {
      blockExplorer = val as BlockExplorer;
    }
  });
  return blockExplorer!;
};

export const getBlockExplorerUrl = (network: Network) => {
  const blockExplorer = getBlockExplorer(network);
  const blockExplorerWebUrl = blockExplorerUrl[blockExplorer];
  return blockExplorerWebUrl;
};

export const getBlockExplorerTransactionsByWalletUrl = (
  network: Network,
  address: string,
  params?: ParsedUrlQuery
) => {
  const url = getBlockExplorerUrl(network);
  return `${url}/address/${address}${params ? `?${qs.stringify(params)}` : ""}`;
};
