import {
  isEthNetwork,
  roundToDecimals,
  thousandSeparator,
} from "../../helpers";

export const mapNetworkToSwitchChainId: Record<Network, SupportedChainId> = {
  // bscMainnet: 1,
  sepolia: 11155111,
  // bscTestnet: 97,
  mainnet: 1,
};

export const formatAmount = (value: string | number) =>
  !Number.isNaN(Number(value)) && value
    ? thousandSeparator(roundToDecimals(value))
    : "0.00";

export const formatTokenBalance = (
  value: string,
  walletConnected: boolean,
  network: Network
) => {
  return `${walletConnected ? thousandSeparator(value) : `-- / --`} ${
    isEthNetwork(network) ? "EPAN" : "BEPAN"
  }`;
};

export const formatBridgeSwapAllowance = (
  network: Network,
  value?: Maybe<string>
) =>
  `${roundToDecimals(value ?? 0)} ${isEthNetwork(network) ? "EPAN" : "BEPAN"}`;
