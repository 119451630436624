import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchSpotAccountFailure } from "../redux/spot-account/actions";
import { spotAccountResource } from "../api";
import {
  getSpotAccountDetailsData,
  isSpotInvestmentRedeemable,
} from "../helpers";
import { useMemo } from "react";
import {
  DEFAULT_REFETCH_INTERVAL,
  METAX_BALANCE_PRECISION,
} from "../constants";

export const FETCH_SPOT_ACCOUNT_CACHE_KEY = "FETCH_SPOT_ACCOUNT_CACHE_KEY";

export const useFetchSpotAccountTotalBalance = () => {
  const {
    data: spotAccountTotalBalance,
    isLoading: isLoadingSpotAccountTotalBalance,
  } = useQuery(
    ["SpotAccountTotalBalance"],
    () => spotAccountResource.fetchSpotAccountTotalBalance(),
    {
      staleTime: DEFAULT_REFETCH_INTERVAL,
      cacheTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
    }
  );

  return {
    spotAccountTotalBalance,
    isLoadingSpotAccountTotalBalance,
  };
};

export const useFetchSpotAccount = () => {
  const dispatch = useDispatch();

  const {
    data: spotAccountBalances = [],
    isLoading: isLoadingSpotAccountBalances,
    refetch: refetchSpotAccountBalances,
  } = useQuery<IApiSpotAccountBalance[], Error>(
    [FETCH_SPOT_ACCOUNT_CACHE_KEY],
    () => spotAccountResource.fetchSpotAccount(),
    {
      staleTime: DEFAULT_REFETCH_INTERVAL,
      cacheTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      onError: (e) => {
        dispatch(fetchSpotAccountFailure(e));
      },
    }
  );

  const isActiveSpotAccount = Boolean(spotAccountBalances.length);

  const spotAccountDetails = useMemo<IGetSpotAccountDetailsResponse>(() => {
    const precision = 10 ** METAX_BALANCE_PRECISION;
    const balance =
      Math.floor(
        spotAccountBalances.reduce((acc, cur) => acc + Number(cur.amount), 0) *
          precision
      ) / precision;

    return {
      balance,
      currency: "METAX",
      ...getSpotAccountDetailsData(spotAccountBalances),
      investments: spotAccountBalances.map<ISpotAccountInvestmentAsset>(
        (spot) => ({
          id: spot._id,
          transactionId: spot.transactionId,
          value: Math.floor(Number(spot.amount) * precision) / precision,
          currency: "METAX",
          redeemableDate: spot.redeemDate,
          initialAmount: spot.initialAmount,
          fiatCurrency: spot.currency as TSupportedFiatCurrencies,
          amountForPreferredCurrency: spot.amountForPreferredCurrency,
          exchangeRate: spot.exchangeRate?.length
            ? spot.exchangeRate[0]
            : [
                {
                  currency: "USD",
                  rate: "0",
                  currentPrice: "0",
                  difference: "0",
                  initialPrice: "0",
                },
              ],
        })
      ),
    };
  }, [spotAccountBalances]);

  const isRedeemable = isSpotInvestmentRedeemable(
    spotAccountDetails?.nearestRedeemableDate ?? ""
  );

  return {
    spotAccountBalances,
    isLoadingSpotAccountBalances,
    refetchSpotAccountBalances,
    spotAccountDetails,
    isRedeemable,
    isActiveSpotAccount,
  };
};
