import React from "react";
import cx from "classnames";
import s from "./style.module.scss";
import {
  CommonTileContainer,
  TokenIconRounded,
  CommonButton,
  Typography,
} from "../../../../components";
import { ISpotAccountInvestmentsProps } from "./types";
import { useTranslation } from "react-i18next";
import { ReactComponent as LockIcon } from "../../../../assets/images/icons/lock.svg";
import EmptyInvestmentsIcon from "../../../../assets/images/icons/empty-investments-icon.svg";
import { thousandSeparator } from "../../../../helpers/thousandSeparator";
import { roundToDecimals } from "../../../../helpers";
import moment from "moment";
import { Config } from "../../../../constants/env.config";
import { useSelector } from "react-redux";
import { getCurrentNetwork } from "../../../../redux/blockchain/selectors";

export const SpotAccountInvestments: React.FC<ISpotAccountInvestmentsProps> = ({
  investments = [],
  onTopUpSpotAccountClick,
  onRedeemClick,
}) => {
  const isProd = Config.ENV === "PRODUCTION";
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation(["dashboard"]);

  return (
    <CommonTileContainer>
      <Typography variant="h4">{t("spot-account")}</Typography>
      <div
        className={cx(s.account_content, {
          [s["account_content_empty"]]:
            (isProd && network === "sepolia") ||
            (!isProd && network === "mainnet") ||
            !investments.length,
        })}
      >
        {((isProd && network === "mainnet") ||
          (!isProd && network === "sepolia")) &&
        investments.length ? (
          <>
            {investments.map((item, idx, arr) => {
              const isLastItem = idx === arr.length - 1;
              return (
                <div
                  key={`${item.value}${idx}`}
                  className={cx({ [s["account_item"]]: !isLastItem })}
                >
                  <Typography variant="bodySmall" color="textSecondary">
                    {t("investment")}
                  </Typography>
                  <div className={s.account_balance_wrapper}>
                    <TokenIconRounded tokenName="METAX" />
                    <Typography className={s.account_balance}>
                      {`${thousandSeparator(
                        roundToDecimals(item.value)
                      )} METAX `}
                      {!!item.fiatEquivalent && (
                        <>
                          <Typography component="span">/ </Typography>
                          <Typography component="span" noWrap>
                            {item.fiatEquivalent}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </div>
                  <div className={s.account_redeemable}>
                    <Typography variant="bodySmall" color="textSecondary">
                      {t("redeemable", {
                        date: moment(item.redeemableDate).format(
                          "DD.MM.YYYY hh:mm"
                        ),
                      })}
                    </Typography>
                  </div>
                  <div className={s.account_button}>
                    <CommonButton
                      size="small"
                      startIcon={!item.isRedeemable && <LockIcon />}
                      disabled={!item.isRedeemable}
                      label={t("common:redeem")}
                      onClick={() =>
                        onRedeemClick!(item.transactionId, item.value)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : investments.length === 0 ? (
          <>
            <img
              className={s.account}
              src={EmptyInvestmentsIcon}
              alt={"Empty"}
            />
            <span className={s.account_investment}>
              {t("make-an-investment")}
            </span>
            <CommonButton
              label={t("spot-account:buy-metax")}
              onClick={onTopUpSpotAccountClick}
            />
          </>
        ) : (
          <>
            <span className={s.account_investment}>
              {isProd && network === "sepolia"
                ? t("available-only-on-mainnet")
                : t("available-only-on-testnet")}
            </span>
          </>
        )}
      </div>
    </CommonTileContainer>
  );
};
