import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { transactionsResource } from "../../api";
import {
  // BSC_NETWORKS,
  BSC_TOKENS,
  ETH_NETWORKS,
  ETH_TOKENS,
  SUPPORTED_FIAT_CURRENCIES,
} from "../../constants";
import { isEthNetwork } from "../../helpers";
import { useWalletConnector } from "../../hooks";
import { getCurrentNetwork } from "../../redux/blockchain/selectors";

export const useTransactionHistory = ({
  transactionType,
  coin,
}: {
  transactionType: string;
  coin: string;
}): [boolean, IMatchedTransaction[]] => {
  const { connectedAddress } = useWalletConnector();
  const network = useSelector(getCurrentNetwork);
  const [isLoading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<IMatchedTransaction[]>([]);
  useEffect(() => {
    (async () => {
      try {
        if (connectedAddress && network && transactionType && coin) {
          const isValidCoin =
            (isEthNetwork(network) && coin in ETH_TOKENS[network]) ||
            ETH_NETWORKS[network].native_currency.symbol === coin ||
            coin === "CUSTOM";
          if (isValidCoin) {
            setLoading(true);
            const matchedTransactions =
              await transactionsResource.getTransactions({
                address: connectedAddress,
                type: transactionType,
                currency: coin as DefaultWalletCurrencies,
                fiatCurrency: SUPPORTED_FIAT_CURRENCIES.USD.currency,
                network: network,
                limit: 25,
                page: 1,
              });
            setLoading(false);
            setTransactions(matchedTransactions);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [connectedAddress, network, transactionType, coin]);

  return [isLoading, transactions];
};
