import {
  ENV_STORAGE_KEY,
  envConfig,
  blockExplorerApiBaseUrl,
  ETH_NETWORKS,
  // BSC_NETWORKS,
  ETH_TOKENS,
  STAGE_ETH_TOKENS,
  STAGE_BSC_TOKENS,
  BSC_TOKENS,
  STAGE_ETH_NETWORKS,
  // STAGE_BSC_NETWORKS,
  __DEV__,
} from "../constants";
import { Config } from "../constants/env.config";

class EnvService {
  constructor() {
    this._envConfig = this.getAppConfig();
  }

  private _envConfig: IEnvConfig;

  get envConfig(): IEnvConfig {
    return this._envConfig;
  }

  async init() {
    try {
      const value = await localStorage.getItem(ENV_STORAGE_KEY);

      if (value) {
        this._envConfig = this.getAppConfig(value as IEnv);
      }
    } catch (e) {
      __DEV__ && console.log("-------- e", e);
    }
  }

  async changeEnv(env: IEnv) {
    try {
      await localStorage.setItem(ENV_STORAGE_KEY, env);
      alert("Attention, reload the app for the ENV changes to take effect");
    } catch (e) {
      __DEV__ && console.log("-------- e", e);
    }
  }

  getAppConfig(env?: IEnv): IEnvConfig {
    if (!env) {
      return envConfig[Config.ENV as IEnv];
    }

    return envConfig[env];
  }

  get chain(): IEthNetworkData {
    return this._envConfig.ENV !== "PRODUCTION"
      ? { ...ETH_NETWORKS.sepolia, infuraId: this._envConfig.INFURA_ID }
      : { ...ETH_NETWORKS.mainnet, infuraId: this._envConfig.INFURA_ID };
  }

  getInfuraWSS(chain?: EthNetwork): string {
    return `wss://${chain ?? this.chain.network}.infura.io/ws/v3/${
      this.chain.infuraId
    }`;
  }

  // getBscRpc(network?: BscNetwork): string {
  //   const defaultNetwork: BscNetwork =
  //     this._envConfig.ENV !== "PRODUCTION" ? "bscTestnet" : "bscMainnet";

  //   return BSC_NETWORKS[network ?? defaultNetwork].rpc;
  // }

  get apiBaseUrl(): string {
    return this.envConfig.API_URL;
  }

  getBlockExplorerBaseUrl(network: Network): string {
    return blockExplorerApiBaseUrl[network];
  }

  get etherScanApiKey(): string {
    return this.envConfig.ETHERSCAN_API_KEY;
  }

  get bscScanApiKey(): string {
    return this.envConfig.BSCSCAN_API_KEY;
  }

  get walletConnectBridge() {
    return this.envConfig.WALLET_CONNECT_BRIDGE;
  }

  get auth0ManagementApiAudience() {
    return this.envConfig.AUTH0_MANAGEMENT_API_AUDIENCE;
  }

  get auth0ApiAudience() {
    return this.envConfig.AUTH0_API_AUDIENCE;
  }

  get withDevReducer() {
    return this.envConfig.ENV === "DEV" || this.envConfig.ENV === "STAGE";
  }

  get showEnvSelector() {
    return Config.ENV === "DEV";
  }

  getEthTokens(network: EthNetwork) {
    if (this._envConfig.ENV === "STAGE") {
      return STAGE_ETH_TOKENS[network];
    }

    return ETH_TOKENS[network];
  }

  getBscTokens(network: BscNetwork) {
    if (this._envConfig.ENV === "STAGE") {
      return STAGE_BSC_TOKENS[network];
    }

    return BSC_TOKENS[network];
  }

  getEthNetworks(network: EthNetwork) {
    if (this._envConfig.ENV === "STAGE") {
      return STAGE_ETH_NETWORKS[network];
    }

    return ETH_NETWORKS[network];
  }

  // getBscNetworks(network: BscNetwork) {
  //   if (this._envConfig.ENV === "STAGE") {
  //     return STAGE_BSC_NETWORKS[network];
  //   }

  //   return BSC_NETWORKS[network];
  // }
}

export const envService = new EnvService();
