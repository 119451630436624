import React from "react";
import { Divider } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import EPANTokenIcon from "../../../../assets/images/icons/metax-token-icon-primary.svg";
import coins from "../../../../assets/images/icons/coins.svg";
import calendar from "../../../../assets/images/icons/calendar.svg";
import { CommonButton, CommonTileContainer } from "../../../../components";
import "./style.scss";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { getCurrentFiatCurrency } from "../../../../redux/users/selectors";
import cx from "classnames";
import { FIAT_EXCHANGE_PRECISION, routes } from "../../../../constants";
import { useHistory } from "react-router-dom";

export const Details = (props: IDetailsProps) => {
  const { t } = useTranslation(["spot-account"]);
  const history = useHistory();
  const { currency: fiatCurrency } = useSelector(
    getCurrentFiatCurrency,
    shallowEqual
  );

  return (
    <section className="spot-account-section">
      <div className="spot-account-section-item">
        <div
          className={cx("spot-account-details", {
            "spot-account-details-singular": !props.redeemDate,
          })}
        >
          <div className="spot-account-details-icon-container">
            <div className="spot-account-details-icon">
              <img src={EPANTokenIcon} />
            </div>
          </div>
          <div className="spot-account-details-balance">
            <span className="spot-account-details-balance-title">
              {t("balance")}
            </span>
            <span className="spot-account-details-balance-value">
              {props.balance} {props.currency}
            </span>
            <span className="spot-account-details-balance-fiat-value">
              {(props.balance * props.currencyPrice).toFixed(
                FIAT_EXCHANGE_PRECISION
              )}{" "}
              {fiatCurrency}
            </span>
          </div>
          <div className="spot-account-details-top-up">
            <CommonButton
              design="greyish"
              labelClassName="spot-account-details-top-up-label"
              label={t("buy-metax")}
              onClick={() => history.push(routes.BUY_METAX_BUY)}
            />
          </div>
          <Divider />
          <div className="spot-account-details-available-to-redeem">
            <img src={coins} />
            <div className="spot-account-details-available-to-redeem-info">
              <span className="spot-account-details-available-to-redeem-info-title">
                {t("available-to-redeem")}
              </span>
              <span className="spot-account-details-available-to-redeem-info-value">
                {props.redeemable} {props.currency} /{" "}
                {(props.redeemable * props.currencyPrice).toFixed(
                  FIAT_EXCHANGE_PRECISION
                )}{" "}
                {fiatCurrency}
              </span>
            </div>
          </div>
        </div>
        {props.redeemDate && (
          <CommonTileContainer>
            <div className="spot-account-details-nearest-redeem-date">
              <img src={calendar} />
              <span className="spot-account-details-nearest-redeem-date-text">
                <Trans
                  ns="spot-account"
                  i18nKey="nearest-date-to-redeem"
                  values={{
                    date: moment(props.redeemDate).format("DD.MM.YYYY"),
                  }}
                  components={{
                    value: (
                      <span className="spot-account-details-nearest-redeem-date-value" />
                    ),
                  }}
                />
              </span>
            </div>
          </CommonTileContainer>
        )}
      </div>
    </section>
  );
};
