import {
  ApiErrorResponse,
  ApiResponse,
  ApisauceInstance,
  create,
} from "apisauce";
import {
  HttpCancelError,
  HttpClientError,
  HttpClientUnauthorizedError,
  HttpClientValidationError,
  HttpConnectionError,
  HttpNetworkError,
  HttpServerError,
  HttpTimeoutError,
  HttpUnknownError,
} from "./errors";
import { makeLogRequestMonitor } from "./logger";
import {
  __DEV__,
  APP_VERSION,
  envConfig,
  REQUEST_TIMEOUT,
} from "../../constants";
import { translate } from "../../api";
import { envService } from "../env.service";
import { Config } from "../../constants/env.config";

class HTTPService {
  static AUTH_HEADER_KEY = "Authorization";

  private instance: ApisauceInstance;
  private readonly apisauceLogger: ReturnType<typeof makeLogRequestMonitor>;

  constructor() {
    this.apisauceLogger = makeLogRequestMonitor(true);
    this.instance = this.instance = create({
      baseURL: `${envService.apiBaseUrl}`,
      timeout: REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "accept-version": Config.REACT_APP_VERSION,
      },
    });

    if (__DEV__) {
      this.instance.addMonitor(this.apisauceLogger);
    }
  }

  setUpBaseUrl(url = `${envService.apiBaseUrl}`) {
    this.instance.setBaseURL(url);
  }

  async get<Response>({
    url,
    params = {},
    headers,
    errorSelector,
    errorStatusMessages,
    baseURL,
  }: HttpMethodParamsType) {
    const response = await this.instance.get<Response, IErrorResponse>(
      url,
      params,
      {
        headers,
        baseURL,
      }
    );

    return this.handleResponse(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  async post<Response>({
    url,
    data,
    headers,
    errorSelector,
    errorStatusMessages,
    baseURL,
    timeout,
  }: HttpMethodParamsType) {
    const response = await this.instance.post<Response, IErrorResponse>(
      url,
      data,
      {
        headers,
        baseURL,
        timeout,
      }
    );

    return this.handleResponse<Response>(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  async patch<Response>({
    url,
    data,
    headers,
    errorSelector,
    errorStatusMessages,
    baseURL,
  }: HttpMethodParamsType) {
    const response = await this.instance.patch<Response, IErrorResponse>(
      url,
      JSON.stringify(data),
      { headers, baseURL }
    );

    return this.handleResponse<Response>(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  async put<Response>({
    url,
    data,
    headers,
    errorSelector,
    errorStatusMessages,
  }: HttpMethodParamsType) {
    const response = await this.instance.put<Response, IErrorResponse>(
      url,
      JSON.stringify(data),
      {
        headers,
      }
    );

    return this.handleResponse<Response>(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  async delete<Response>({
    url,
    data,
    params = {},
    headers,
    errorSelector,
    errorStatusMessages,
    baseURL,
  }: HttpMethodParamsType) {
    const response = await this.instance.delete<Response, IErrorResponse>(
      url,
      params,
      {
        headers,
        baseURL,
        data,
      }
    );

    return this.handleResponse<Response>(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  async sendFormData<Response>({
    method,
    url,
    fileApiKey,
    file,
    serializableData = {},
    errorSelector,
    errorStatusMessages,
  }: ISendFormDataParams) {
    const formData = new FormData();
    Object.keys(serializableData).map((val) => {
      formData.append(val, serializableData[val]);
    });
    formData.append(fileApiKey, file as unknown as Blob);
    const response = await this.instance[method]<Response, IErrorResponse>(
      url,
      formData
    );
    return this.handleResponse<Response>(response, {
      errorSelector,
      errorStatusMessages,
    });
  }

  handleResponse<Response>(
    response: ApiResponse<Response, IErrorResponse>,
    { errorSelector, errorStatusMessages }: IErrorMessagesOptions
  ) {
    if (response.ok) {
      return response.data;
    }
    console.log({ response });

    switch (response.problem) {
      case "CLIENT_ERROR": {
        if (response.status === 400) {
          this.handle400Errors(response);
        } else if (response.status === 401) {
          this.handle401Errors(response);
        }
        if (response.status === 403) {
          this.handle403Errors(response);
        }

        if (errorSelector && response.data && errorSelector(response.data)) {
          throw new HttpClientError(errorSelector(response.data));
        }

        if (
          errorStatusMessages &&
          response.status &&
          errorStatusMessages[response.status]
        ) {
          throw new HttpClientError(errorStatusMessages[response.status]);
        }

        if (
          response.data?.message &&
          typeof response.data.message === "string"
        ) {
          throw new HttpClientError(response.data.message);
        }

        throw new HttpClientError("unexpected error");
      }
      case "SERVER_ERROR":
        throw new HttpServerError("server error");
      case "TIMEOUT_ERROR":
        throw new HttpTimeoutError("timeout error");
      case "CONNECTION_ERROR":
        throw new HttpConnectionError("lost connection error");
      case "NETWORK_ERROR":
        throw new HttpNetworkError("network error");
      case "UNKNOWN_ERROR":
        throw new HttpUnknownError("unexpected error");
      case "CANCEL_ERROR":
        throw new HttpCancelError("cancel request error");
      default:
        throw new HttpUnknownError("unexpected error");
    }
  }

  setAuthHeader(token: string) {
    this.instance.setHeader(HTTPService.AUTH_HEADER_KEY, `Bearer ${token}`);
  }

  removeAuthHeader() {
    this.instance.deleteHeader(HTTPService.AUTH_HEADER_KEY);
  }

  protected handle403Errors(_: ApiErrorResponse<IErrorResponse>) {
    throw new HttpClientError();
  }

  protected handle401Errors(_: ApiErrorResponse<IErrorResponse>) {
    throw new HttpClientUnauthorizedError(translate("token-expired", "errors"));
  }

  protected handle400Errors(response: ApiErrorResponse<IErrorResponse>) {
    if (!response?.data?.message) {
      throw new HttpClientValidationError(
        translate("validation-error", "errors")
      );
    }

    if (typeof response.data.message === "string") {
      throw new HttpClientValidationError(response.data.message);
    } else if (Array.isArray(response.data.message)) {
      const error = response.data.message[0];

      throw new HttpClientValidationError(
        Object.values(error.constraints).join(", ")
      );
    }

    throw new HttpClientValidationError(
      translate("validation-error", "errors")
    );
  }
}

export const httpService = new HTTPService();
