import Identicon from 'identicon.js';

export function generateBase64CryptoIcon(address: string, size: number) {
  const icon = new Identicon(address, {
    size,
    background: [217, 90, 206, 255],
    margin: 0.25,
  }).toString();

  return `data:image/png;base64,${icon}`;
}
