import moment from 'moment';

type Props = {
  date: string;
  format?: string;
};

export const formatDate = ({ date, format = 'DD.MM.YYYY' }: Props): string => {
  try {
    return moment(date).format(format);
  } catch {
    return date;
  }
};

export const getTime = (date: string) => {
  return moment(date).toDate().getTime();
};
