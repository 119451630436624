import { useCallback, useState } from 'react';
import { getEllipsizeString } from '../../helpers';

export const useInfoModal = () => {
  const [infoModalValues, setInfoModalValues] = useState<Maybe<ITxItemModalInfoValues>>(null);

  const onDismissInfoModal = useCallback(() => {
    setInfoModalValues(null);
  }, []);

  const openInfoModal = useCallback(
    (values: IMatchedTransaction) => () => {
      setInfoModalValues({
        ...values,
        title: values.smartContract?.label ?? '',
        from: getEllipsizeString(values?.from ?? ''),
        to: getEllipsizeString(values?.to ?? ''),
        hash: getEllipsizeString(values?.hash ?? ''),
        contractAddress: getEllipsizeString(values?.contractAddress ?? ''),
      });
    },
    [],
  );

  return {
    openInfoModal,
    infoModalValues,
    onDismissInfoModal,
  };
};
