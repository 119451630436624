import React from "react";
import "./style.scss";
import cx from "classnames";
import {
  DefaultLoader,
  PageContentContainer,
  SegmentedControl,
} from "../../components";
import { useWalletConnector } from "../../hooks";
import {
  DisconnectedWallet,
  EmptyList,
  InfoModal,
  ListHeader,
  TxItem,
} from "./components";
import { useTranslation } from "react-i18next";
import { useInfoModal } from "./use-info-modal";
import { getSegmentedControlTabs } from "./helpers";
import { useSelector } from "react-redux";
import { useScreen } from "./use-screen";
import {
  getCurrentNetwork,
  getExternalWalletAddresses,
} from "../../redux/blockchain/selectors";
import { ViewTxButton } from "./components/view-tx-button";
import { useTransactionHistory } from "./use-transaction-history";
import { useSegmentedControl } from "../../components/segmented-control/use-segmented-control";
import { useFilterData } from "./use-filter-data";
import { getBlockExplorerTransactionsByWalletUrl } from "../../helpers";

export const TransactionHistoryPage = () => {
  const { onSelectFilter, filters } = useScreen();
  const { infoModalValues, onDismissInfoModal, openInfoModal } = useInfoModal();
  const { t } = useTranslation(["transactions-history"]);
  const { walletConnected, isChainSupported } = useWalletConnector();
  const network = useSelector(getCurrentNetwork);
  const address = useSelector(getExternalWalletAddresses);
  const { value: transactionType, handleChange: handleTransactionChange } =
    useSegmentedControl({
      tabs: getSegmentedControlTabs(t),
    });
  const [isLoading, transactions] = useTransactionHistory({
    transactionType: transactionType as string,
    coin: filters.coin,
  });
  const handleTransactionListRedirect = () => {
    const url = getBlockExplorerTransactionsByWalletUrl(network, address[0]);
    window.open(url, "_blank");
  };
  const filterData = useFilterData();

  return (
    <>
      <div className="tx-history">
        {walletConnected ? (
          <>
            <ListHeader
              selectedFilters={filters}
              filtersDisabled={false}
              onSelectFilter={onSelectFilter}
              filtersData={filterData}
            />
            <PageContentContainer>
              <SegmentedControl
                onChange={(val) => handleTransactionChange(null, val)}
                tabs={getSegmentedControlTabs(t)}
              />
              <div className="tx-history-items-wrapper">
                {isLoading ? (
                  <DefaultLoader containerClass="tx-history-loader" />
                ) : transactions.length !== 0 ? (
                  transactions.map((item, idx, arr) => {
                    const isLastItem = idx === arr.length - 1;
                    return (
                      <TxItem
                        {...item}
                        key={`${item.amount}${idx}`}
                        transactionType={transactionType}
                        onClick={openInfoModal}
                        className={cx({
                          "tx-history-item-margin": !isLastItem,
                        })}
                      />
                    );
                  })
                ) : (
                  <EmptyList />
                )}
              </div>
            </PageContentContainer>
            <ViewTxButton
              network={network}
              onScanClick={handleTransactionListRedirect}
              containerClass="tx-history-scan-btn"
            />
          </>
        ) : (
          <DisconnectedWallet isChainSupported={isChainSupported} />
        )}
      </div>
      {!!infoModalValues && (
        <InfoModal
          {...infoModalValues}
          transactionType={transactionType}
          visible={Boolean(infoModalValues)}
          onDismiss={onDismissInfoModal}
        />
      )}
    </>
  );
};
