import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { usersResource } from '../api';
import { setCurrentFiatCurrency, setPreferencesFailure } from '../redux/users/actions';
import { getCurrentFiatCurrency } from '../redux/users/selectors';

export const useCurrentFiatCurrency = () => {
  const { currency, symbol } = useSelector(getCurrentFiatCurrency);
  const dispatch = useDispatch();

  const { mutateAsync: setCurrencyRequest, isLoading } = useMutation<
    void,
    Error,
    IPreferencesParams
  >(usersResource.setPreferences);

  const selectCurrency = useCallback(
    async (value: IFiatCurrency) => {
      if (currency === value.currency) {
        return;
      }

      try {
        await setCurrencyRequest({ preferredCurrency: value.currency });
        dispatch(setCurrentFiatCurrency(value));
      } catch (err) {
        dispatch(setPreferencesFailure(err as Error));
      }
    },
    [dispatch, setCurrencyRequest, currency],
  );

  return {
    currency,
    symbol,
    selectCurrency,
    isLoading,
  };
};
