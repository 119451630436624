import { useQuery } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { web3Service } from "../services/blockchain";
import { getWalletsBalancesBlockchainError } from "../redux/blockchain/actions";
import { useCallback } from "react";
import {
  getCurrentNetwork,
  getCustomTokes,
} from "../redux/blockchain/selectors";
import { useExchangeRates } from "./use-exchange-rates";
import { getCurrentFiatCurrency } from "../redux/users/selectors";
import {
  DEFAULT_REFETCH_INTERVAL,
  FIAT_EXCHANGE_PRECISION,
  DEFAULT_WALLET_CURRENCIES,
  __DEV__,
} from "../constants";
import { useWalletConnector } from "./use-wallet-connector";

interface IUseWalletBalancesParams {
  wallets: IWallet[];
  onSuccess?: () => void;
}

export const WALLETS_BALANCES_CACHE_KEY = "WALLETS_BALANCES_CACHE_KEY";

export const useWalletsBalances = ({
  wallets,
  onSuccess,
}: IUseWalletBalancesParams) => {
  const dispatch = useDispatch();
  const customErc20Tokes = useSelector(getCustomTokes, shallowEqual);
  const network = useSelector(getCurrentNetwork);
  const { walletEnabled } = useWalletConnector();

  const {
    currenciesQuotes,
    refetchCurrenciesQuotes,
    isLoadingCurrenciesQuotes,
  } = useExchangeRates();

  const { currency: fiatCurrency } = useSelector(
    getCurrentFiatCurrency,
    shallowEqual
  );

  const {
    data: walletsBalances,
    isLoading: isLoadingWalletsBalances,
    refetch: refetchWalletsBalances,
  } = useQuery<IWalletWithBalance[], Error>(
    [WALLETS_BALANCES_CACHE_KEY, wallets, network],
    async (): Promise<IWalletWithBalance[]> => {
      const promises = wallets.map(async (wallet) => {
        try {
          const balance = await web3Service.getBalance(
            wallet,
            network,
            customErc20Tokes
          );

          return {
            ...wallet,
            balance: balance || 0,
          };
        } catch (error) {
          return null;
        }
      });

      const results = await Promise.all(promises);

      const uniqueResults = results
        .filter(Boolean)
        .reduce<IWalletWithBalance[]>((acc, current) => {
          if (
            !acc.some((item) => item.tokenAddress === current?.tokenAddress)
          ) {
            acc.push(current as any);
          }
          return acc;
        }, []);

      return uniqueResults;
    },
    {
      enabled: !!wallets.length && walletEnabled,
      staleTime: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      cacheTime: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      refetchInterval: __DEV__ ? 5 * 60 * 1000 : DEFAULT_REFETCH_INTERVAL,
      retry: 2,
      retryDelay: (count) => (count === 1 ? 300 : 1000),
      onSuccess,
      onError: (e) => {
        dispatch(getWalletsBalancesBlockchainError(e));
      },
    }
  );

  const positiveBalances = (walletsBalances ?? [])
    .filter((wallet) => {
      if (
        wallet?.type === "metamaskExtension" ||
        wallet?.type === "coinbase" ||
        DEFAULT_WALLET_CURRENCIES.includes(wallet?.currency)
      ) {
        return true;
      }

      return wallet?.balance && parseFloat(wallet?.balance) > 0;
    })
    .map((item) => {
      if (currenciesQuotes) {
        const balance = Number(item.balance ?? 0);
        const currencyRate = currenciesQuotes.quotes[item.currency]
          ? currenciesQuotes.quotes[item.currency][fiatCurrency]?.price ?? 0
          : 0;
        const fiatValue = (balance * currencyRate).toFixed(
          FIAT_EXCHANGE_PRECISION
        );

        return {
          ...item,
          fiatValue,
          fiatCurrency,
        };
      }
      return item;
    });

  const onRefreshWalletBalances = useCallback(() => {
    refetchWalletsBalances();
    refetchCurrenciesQuotes();
  }, [refetchWalletsBalances, refetchCurrenciesQuotes]);

  return {
    walletsBalances: positiveBalances,
    isLoadingWalletsBalances:
      isLoadingCurrenciesQuotes || isLoadingWalletsBalances,
    onRefreshWalletBalances,
  };
};
