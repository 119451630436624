// key needs to be equal to value
export const routes = {
  SIGN_IN: "/auth/login",
  SIGN_UP: "/auth/registration",
  RESET_PASSWORD: "/auth/reset-password",
  DASHBOARD: "/dashboard",
  VERSION: "/version",
  FIAT_PAYMENT: "/fiat-payment",
  STAKING: "/dashboard/staking",
  SPOT_ACCOUNT: "/dashboard/spot-account",
  CROSS_CHAIN_BRIDGE: "/dashboard/cross-chain-bridge",
  TRANSACTION_HISTORY: "/dashboard/transaction-history",
  SETTINGS: "/dashboard/settings",
  REDEEM_SPOT_ACCOUNT: "/dashboard/spot-account/redeem",
  TOP_UP_SPOT_ACCOUNT: "/dashboard/spot-account/top-up",
  SMART_CREDIT: "/dashboard/smart-credit",
  BUY_METAX: "/dashboard/buy-metax",
  BUY_METAX_BUY: "/dashboard/buy-metax/buy",
  REDEEM_METAX: "/dashboard/buy-metax/redeem",
} as const;
